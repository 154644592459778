import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import { MODAL_TYPES } from "../../../base/constants/modal";
import cn from "classnames";

export const CustomModal = ({
  isOpen,
  onClose,
  onConfirm,
  isCustomFooter = false,
  confirmBtnText = "Confirm",
  cancelBtnText = "Cancel",
  confirmBtnClassname,
  title,
  children,
  type,
}) => {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={onClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
      {!isCustomFooter && (
        <div className="modal-footer">
          {type === MODAL_TYPES.QUESTION ? (
            <>
              <button
                type="button"
                onClick={onClose}
                className="btn btn-outline-secondary w-xs"
                data-dismiss="modal"
              >
                {cancelBtnText}
              </button>
              <button
                type="button"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                className={cn("btn btn-primary w-xs", confirmBtnClassname)}
                data-dismiss="modal"
              >
                {confirmBtnText}
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={onClose}
              className="btn btn-primary w-xs"
              data-dismiss="modal"
            >
              Ok
            </button>
          )}
        </div>
      )}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  isCustomFooter: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string,
};
