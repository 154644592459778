export const CUSTOM_LESSON_TYPE = "Custom lesson";
export const ESSENTIAL_LESSON_TYPE = "Essential lesson";

export const WORDS_TYPES = {
  VOCABULARY: 1,
  SENTENCES: 2,
};

export const CATEGORY_IDS = {
  CUSTOM: "0",
  ESSENTIAL: "1",
  EDUCATION_PROFESSION_INDUSTRIES: "2",
  SPORTS_HOBBIES_INTERESTS: "3",
  LIFE_TRAVEL_LOCATION: "4",
};

export const ESSENTIAL_FILTER_VALUE_VALUE = 1;

export const EDUCATION_PROFESSIONAL_INDUSTRIES_LABEL =
  "Profession, Industry & Education";
export const EDUCATION_PROFESSIONAL_INDUSTRIES_VALUE = 2;

export const HOBBIES_SPORTS_INTERESTS_LABEL = "Hobbies, Sports & Interests";
export const HOBBIES_SPORTS_INTERESTS_VALUE = 3;

export const LIFE_TRAVEL_YOUR_LOCATION_LABEL = "Life, Travel & Your location";
export const LIFE_TRAVEL_YOUR_LOCATION_VALUE = 4;

export const CATEGORIES = {
  EDUCATION_PROFESSION_INDUSTRIES: EDUCATION_PROFESSIONAL_INDUSTRIES_LABEL,
  SPORTS_HOBBIES_INTERESTS: HOBBIES_SPORTS_INTERESTS_LABEL,
  LIFE_TRAVEL_LOCATION: LIFE_TRAVEL_YOUR_LOCATION_LABEL,
};

export const DEFAULT_THEMES_FILTER_VALUE =
  EDUCATION_PROFESSIONAL_INDUSTRIES_VALUE;

export const LESSON_THEMES_FILTER_OPTIONS = [
  {
    label: EDUCATION_PROFESSIONAL_INDUSTRIES_LABEL,
    value: EDUCATION_PROFESSIONAL_INDUSTRIES_VALUE,
  },
  {
    label: HOBBIES_SPORTS_INTERESTS_LABEL,
    value: HOBBIES_SPORTS_INTERESTS_VALUE,
  },
  {
    label: LIFE_TRAVEL_YOUR_LOCATION_LABEL,
    value: LIFE_TRAVEL_YOUR_LOCATION_VALUE,
  },
];

export const INITIAL_FILTER_OPTION = LESSON_THEMES_FILTER_OPTIONS.find(
  (item) => item.value === DEFAULT_THEMES_FILTER_VALUE
);

export const LESSON_SORT_PROPERTY = "lessonNumber";
