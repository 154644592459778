import React, { useCallback } from 'react'
import PropTypes from 'prop-types';

import { Editor } from 'react-draft-wysiwyg';

const CustomEditor = ({
  keyState,
  editorState,
  onChange,
  apperClassName = "editor-wrapper",
  editorClassName = "editor-main",
  toolbarClassName = "editor-toolbar",
  toolbarHidden = false,  
  placeholder,
}) => {
  // If using Editor with formik should be pass key of 'formik' which change state

  const handleChange = useCallback((keyState, editorState) => {
    keyState ? onChange(keyState, editorState) : onChange(editorState)
  }, []);

  return (
    <Editor 
      editorState={editorState}
      onEditorStateChange={(editorState) => handleChange(keyState, editorState)}
      wrapperClassName={apperClassName}
      editorClassName={editorClassName}
      toolbarClassName={toolbarClassName}
      toolbarHidden={toolbarHidden}
      placeholder={placeholder} 
    />
  )
};

CustomEditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  keyState: PropTypes.string,
  apperClassName: PropTypes.string,
  editorClassName: PropTypes.string,
  toolbarClassName: PropTypes.string,
  toolbarHidden: PropTypes.bool,  
  placeholder: PropTypes.string
}

export default CustomEditor;
