import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Col, Row } from "reactstrap";
import _ from "lodash";

import FormikSelect from "../../../../../../base/components/FormikSelect";
import FormikDropzone from "../../../../../../base/components/FormikDropZone";
import FormikInput from "../../../../../../base/components/FormikInput";
import FormikTextarea from "../../../../../../base/components/FormikTextarea";
import FormikTextEditor from "../../../../../../base/components/FormikTextEditor";
import {
  BUTTON_COLORS,
  BUTTON_SIZES,
} from "../../../../../../base/components/Button/appearance";
import Button from "../../../../../../base/components/Button";
import {
  SENTENCES_ACCESSORS,
  SENTENCES_HEADERS,
  sentencesTableStructure,
  WORDS_ACCESSORS,
  WORDS_HEADERS,
  wordsTableStructure,
} from "./components/tableStructure";
import XslTable from "./components/XslTable";

import { useToaster } from "../../../../../../base/hooks/useToaster";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useService } from "../../../../../../base/hooks/useService";

import {
  MAX_LESSON_INPUT_LENGTH,
  MAX_LESSON_TEXTAREA_LENGTH,
} from "../../../../../../validation/lengthConstants";
import {
  CATEGORIES,
  CATEGORY_IDS,
  CUSTOM_LESSON_TYPE,
  ESSENTIAL_LESSON_TYPE,
  WORDS_TYPES,
} from "../../../../../../base/constants/lesson";
import {
  CREATE_LESSON_SUCCESS_MESSAGE,
  EDIT_LESSON_SUCCESS_MESSAGE,
} from "../../../../../../base/constants/messages";
import { TYPES } from "../../../../../../features/DropzoneFile/constants/dropzoneConstants";

import LessonsService from "../../../../../../services/LessonsService";

import { downloadFile } from "../../../../../../base/helpers/downloadFile";
import { parseXslFile } from "../../../../../../base/helpers/parseXslFile";

import { initialValues, validationSchema } from "./form";

import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { CONTENT_MANAGEMENT_GROUP_LINKS } from "../../../../config";
import { TOASTER_TYPES } from "../../../../../../base/constants/toaster";
import { Toaster } from "react-hot-toast";
import { CustomModal } from "../../../../../auth/components/Modal";

const LESSON_TYPES = [
  { title: ESSENTIAL_LESSON_TYPE, value: CATEGORY_IDS.ESSENTIAL },
  { title: CUSTOM_LESSON_TYPE, value: CATEGORY_IDS.CUSTOM },
];

const LESSON_CATEGORIES = [
  {
    title: CATEGORIES.EDUCATION_PROFESSION_INDUSTRIES,
    value: CATEGORY_IDS.EDUCATION_PROFESSION_INDUSTRIES,
  },
  {
    title: CATEGORIES.SPORTS_HOBBIES_INTERESTS,
    value: CATEGORY_IDS.SPORTS_HOBBIES_INTERESTS,
  },
  {
    title: CATEGORIES.LIFE_TRAVEL_LOCATION,
    value: CATEGORY_IDS.LIFE_TRAVEL_LOCATION,
  },
];
const LIMIT = 30;
const TABLE_DATA_TYPES = {
  SENTENCES: "sentences",
  WORDS: "words",
};

const Form = () => {
  /**
   * @type {LessonsService}
   */
  const lessonsService = useService(LessonsService);
  const [isLoading, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();
  const { lessonId } = useParams();
  const navigate = useNavigate();

  const [themeList, setThemeList] = useState({
    data: [],
    pagination: {},
  });

  const [lessonData, setLessonData] = useState({});

  const [sentencesData, setSentencesData] = useState([]);
  const [wordsData, setWordsData] = useState([]);

  const [deleteWordModal, setDeleteWordModal] = useState({
    isOpen: false,
    dataType: "",
    rowIndex: null,
  });

  const downloadButton = (exampleType) => (
    <Button
      color={BUTTON_COLORS.success}
      size={BUTTON_SIZES.small}
      outlined
      className="mt-2"
      onClick={() => downloadXlsExample(exampleType)}
    >
      <i className="bx bx-download"></i>
      <span className="ml-2"> Download xls example</span>
    </Button>
  );

  const updateTable = (data, rowIndex, id, cellValue) => {
    return data.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [id]: cellValue,
        };
      }
      return row;
    });
  };

  const removeRowFromTable = (data, rowIndex) => {
    return data.filter((row, index) => index !== rowIndex);
  };

  const removeRowSentences = (rowIndex) => {
    setDeleteWordModal({
      isOpen: true,
      dataType: TABLE_DATA_TYPES.SENTENCES,
      rowIndex,
    });
  };

  const removeRowWords = (rowIndex) => {
    setDeleteWordModal({
      isOpen: true,
      dataType: TABLE_DATA_TYPES.WORDS,
      rowIndex,
    });
  };

  const updateSentencesRow = (rowIndex, id, cellValue) => {
    setSentencesData(updateTable(sentencesData, rowIndex, id, cellValue));
  };

  const updateWordsRow = (rowIndex, id, cellValue) => {
    setWordsData(updateTable(wordsData, rowIndex, id, cellValue));
  };

  const getSentencesTableData = (data) => {
    return data.map((row) => ({
      [SENTENCES_ACCESSORS.GERMAN]: row[SENTENCES_HEADERS.GERMAN],
      [SENTENCES_ACCESSORS.ENGLISH]: row[SENTENCES_HEADERS.ENGLISH],
      [SENTENCES_ACCESSORS.WRONG_WORD_1]: row[SENTENCES_HEADERS.WRONG_WORD_1],
      [SENTENCES_ACCESSORS.WRONG_WORD_2]: row[SENTENCES_HEADERS.WRONG_WORD_2],
      [SENTENCES_ACCESSORS.WRONG_WORD_3]: row[SENTENCES_HEADERS.WRONG_WORD_3],
    }));
  };

  const getWordsTableData = (data) => {
    return data.map((row) => ({
      [WORDS_ACCESSORS.GERMAN]: row[WORDS_HEADERS.GERMAN],
      [WORDS_ACCESSORS.ENGLISH]: row[WORDS_HEADERS.ENGLISH],
    }));
  };

  const onSentenceXslDrop = (file) => {
    parseXslFile(file, (result) => {
      setSentencesData(getSentencesTableData(result));
    });
  };

  const onWordsXslDrop = (file) => {
    parseXslFile(file, (result) => {
      setWordsData(getWordsTableData(result));
    });
  };

  const downloadXlsExample = useCallback(
    (exampleType) => {
      registerPromise(lessonsService.getXlsExample({ exampleType })).then(
        (data) => {
          downloadFile(data.link);
        }
      );
    },
    [registerPromise, lessonsService]
  );

  const getThemeList = useCallback(
    (categoryId, themeListLength = 0, offset = 0, themeList = []) => {
      registerPromise(
        lessonsService.getThemeList({
          limit: LIMIT,
          offset,
          categoryId,
        })
      ).then(({ data, pagination }) => {
        setThemeList(() => ({
          pagination,
          data: [...themeList, ...data],
        }));

        if (themeListLength < pagination.totalCount) {
          getThemeList(
            categoryId,
            themeListLength + LIMIT,
            pagination.nextOffset,
            [...themeList, ...data]
          );
        }
      });
    },
    [registerPromise, lessonsService]
  );

  const onAddMoreSentences = (file) => {
    parseXslFile(file, (result) => {
      setSentencesData((prevState) => [
        ...prevState,
        ...getSentencesTableData(result),
      ]);
    });
  };

  const onDeleteSentences = () => {
    setSentencesData([]);
  };

  const onAddMoreWords = (file) => {
    parseXslFile(file, (result) => {
      setWordsData((prevState) => [...prevState, ...getWordsTableData(result)]);
    });
  };

  const onDeleteWords = () => {
    setWordsData([]);
  };

  const getFormattedSentencesData = () => {
    const sentences = sentencesData.map((row) => ({
      wrongWords: [
        row[SENTENCES_ACCESSORS.WRONG_WORD_1],
        row[SENTENCES_ACCESSORS.WRONG_WORD_2],
        row[SENTENCES_ACCESSORS.WRONG_WORD_3],
      ],
      body: row.body,
      translation: row.translation,
      hint: " ",
    }));
    return sentences;
  };

  const getFormattedWordsData = (wordsData) => {
    return wordsData.map(({ word, translation }) => ({ word, translation }));
  };

  const getFormattedValues = (values) => {
    const {
      type,
      category,
      theme,
      title,
      name,
      description,
      content,
      extraInfo,
      fileId,
    } = values;

    return {
      categoryId: type === CATEGORY_IDS.CUSTOM ? +category : +type,
      topicId: theme ? theme : undefined,
      title,
      fileId: fileId ? +fileId : undefined,
      name,
      description: description ? description : undefined,
      body: content ? content : undefined,
      extraInfo: extraInfo ? extraInfo : undefined,
      flashcards: getFormattedWordsData(wordsData),
      sentences: getFormattedSentencesData(),
    };
  };

  const createLesson = useCallback(
    (values, resetForm, setFieldValue) => {
      registerPromise(lessonsService.createLesson(getFormattedValues(values)))
        .then(() => {
          displayToaster(CREATE_LESSON_SUCCESS_MESSAGE);
          navigate(CONTENT_MANAGEMENT_GROUP_LINKS.BASE);
        })
        .catch((error) => {
          error.message.forEach((error) =>
            displayToaster(error, TOASTER_TYPES.ERROR)
          );
        });
    },
    [registerPromise, lessonsService, sentencesData, wordsData]
  );

  const getEditLessonParams = (values) => {
    const formattedSentencesData = lessonData.sentences.map(
      ({ body, hint, translation, wrongWords }) => ({
        body,
        hint,
        translation,
        wrongWords: wrongWords.map(({ word }) => word),
      })
    );
    return {
      isUpdatedFlashcards: !_.isEqual(
        getFormattedWordsData(lessonData.flashcards),
        getFormattedValues(values).flashcards
      ),
      isUpdatedSentences: !_.isEqual(
        formattedSentencesData,
        getFormattedValues(values).sentences
      ),
      sentences: getFormattedValues(values).sentences,
      ...getFormattedValues(values),
    };
  };

  const editLesson = useCallback(
    (lessonId, values) => {
      getEditLessonParams(values);
      registerPromise(
        lessonsService.editLesson(lessonId, getEditLessonParams(values))
      )
        .then(() => {
          displayToaster(EDIT_LESSON_SUCCESS_MESSAGE);
          navigate(CONTENT_MANAGEMENT_GROUP_LINKS.BASE);
        })
        .catch((error) => {
          error.message.forEach((error) =>
            displayToaster(error, TOASTER_TYPES.ERROR)
          );
        });
    },
    [registerPromise, lessonsService, sentencesData, wordsData]
  );

  const getFormattedInitialSentenceData = (sentences) => {
    return sentences.map((sentence) => ({
      ...sentence,
      [SENTENCES_ACCESSORS.WRONG_WORD_1]: sentence.wrongWords[0].word,
      [SENTENCES_ACCESSORS.WRONG_WORD_2]: sentence.wrongWords[1].word,
      [SENTENCES_ACCESSORS.WRONG_WORD_3]: sentence.wrongWords[2].word,
    }));
  };

  const getLessonById = useCallback(
    (id) => {
      registerPromise(lessonsService.getLesson(id)).then((data) => {
        getThemeList(data.categoryId);
        setLessonData(data);
        setWordsData(data.flashcards);
        setSentencesData(getFormattedInitialSentenceData(data.sentences));
      });
    },
    [registerPromise, lessonsService, getThemeList]
  );

  const getInitialFormValues = () => {
    const {
      categoryId,
      file,
      topicId,
      title,
      name,
      description,
      body,
      extraInfo,
    } = lessonData;
    return {
      type:
        categoryId > CATEGORY_IDS.ESSENTIAL
          ? CATEGORY_IDS.CUSTOM
          : CATEGORY_IDS.ESSENTIAL,
      fileId: file?.id || "",
      category: categoryId > CATEGORY_IDS.ESSENTIAL ? categoryId : "",
      theme: topicId || "",
      title: title || "",
      name: name || "",
      description: description || "",
      content: body || "",
      extraInfo: extraInfo || "",
      vocabulary: wordsData.length ? wordsData : [],
      sentences: sentencesData.length ? sentencesData : [],
    };
  };

  useEffect(() => {
    if (lessonId) {
      getLessonById(lessonId);
    }
  }, []);

  return (
    <>
      {((lessonId && lessonData.id) || !lessonId) && (
        <Formik
          initialValues={getInitialFormValues()}
          validationSchema={validationSchema}
          isInitialValid={!!lessonId}
          onSubmit={(values, { resetForm, setFieldValue }) => {
            if (lessonId) {
              editLesson(lessonId, values);
            } else {
              createLesson(values, resetForm, setFieldValue);
            }
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            setFieldValue,
            isValid,
            errors,
          }) => (
            <form
              className="form-horizontal mt-4 d-flex flex-column"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col lg={6}>
                  <FormikSelect
                    name="type"
                    label="Choose lesson type*"
                    containerClassName=""
                    onChange={(e) => {
                      setFieldValue("category", "");
                      setFieldValue("theme", "");

                      handleChange(e);
                    }}
                  >
                    {LESSON_TYPES.map(({ title, value }, index) => (
                      <option value={value} key={index}>
                        {title}
                      </option>
                    ))}
                  </FormikSelect>
                  <FormikDropzone
                    name="fileId"
                    className="image-dropzone"
                    title="Upload image"
                    type={TYPES.IMAGE}
                    initialFile={lessonData.file?.link}
                    initialFileId={lessonData.file?.id}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormikSelect
                    name="category"
                    label="Category*"
                    onChange={(e) => {
                      setThemeList({ pagination: {}, data: [] });
                      setFieldValue("theme", "");
                      getThemeList(e.target.value);

                      handleChange(e);
                    }}
                    containerClassName="mt-3"
                    disabled={values.type !== CATEGORY_IDS.CUSTOM}
                  >
                    <option disabled={true} value="">
                      Choose category
                    </option>
                    {values.type === CATEGORY_IDS.CUSTOM &&
                      LESSON_CATEGORIES.map(({ title, value }, index) => (
                        <option value={value} key={index}>
                          {title}
                        </option>
                      ))}
                  </FormikSelect>
                </Col>

                <Col lg={6}>
                  <FormikSelect
                    name="theme"
                    label="Theme*"
                    containerClassName="mt-3"
                    disabled={values.type !== CATEGORY_IDS.CUSTOM}
                  >
                    <option disabled={true} value="">
                      Choose theme
                    </option>
                    {values.type === CATEGORY_IDS.CUSTOM &&
                      themeList.data.map(({ title, id }, index) => (
                        <option value={id} key={index}>
                          {title}
                        </option>
                      ))}
                  </FormikSelect>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormikInput
                    name="title"
                    label="Title*"
                    placeholder="Enter lessons title"
                    type="text"
                    containerClassName="mt-3"
                    maxLength={MAX_LESSON_INPUT_LENGTH}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormikInput
                    name="name"
                    label="Lessons name*"
                    placeholder="Enter lessons name"
                    type="text"
                    containerClassName="mt-4"
                    maxLength={MAX_LESSON_INPUT_LENGTH}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikTextarea
                    name="description"
                    label="Description"
                    rows="3"
                    placeholder="Enter lessons description"
                    maxLength={MAX_LESSON_TEXTAREA_LENGTH}
                    containerClassName="mt-4 relative"
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <h5>Lesson content</h5>
                <FormikTextEditor
                  name="content"
                  placeholder={"Enter lessons content"}
                />
              </Row>
              <Row className="mt-5">
                <h5>Extra info</h5>
                <FormikTextEditor
                  name="extraInfo"
                  placeholder="Enter Extra info"
                />
              </Row>
              <Row className="mt-5">
                <h5>Vocabulary/Word translation</h5>
                <Col lg={3}>{downloadButton(WORDS_TYPES.VOCABULARY)}</Col>
              </Row>
              <Row>
                <Col>
                  <XslTable
                    name="vocabulary"
                    onDrop={onWordsXslDrop}
                    onAddMore={onAddMoreWords}
                    onDelete={onDeleteWords}
                    onUpdateRow={updateWordsRow}
                    onRemoveRow={removeRowWords}
                    tableData={wordsData}
                    columnData={wordsTableStructure}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <h5>Sentences</h5>
                <Col lg={3}>{downloadButton(WORDS_TYPES.SENTENCES)}</Col>
              </Row>
              <Row>
                <Col>
                  <XslTable
                    name="sentences"
                    onDrop={onSentenceXslDrop}
                    onAddMore={onAddMoreSentences}
                    onDelete={onDeleteSentences}
                    onRemoveRow={removeRowSentences}
                    onUpdateRow={updateSentencesRow}
                    tableData={sentencesData}
                    columnData={sentencesTableStructure}
                  />
                </Col>
              </Row>
              <div className="d-flex align-self-end gap-2 mt-5">
                <Button
                  color={BUTTON_COLORS.secondary}
                  outlined
                  onClick={() => navigate(CONTENT_MANAGEMENT_GROUP_LINKS.BASE)}
                  className="create-lesson-btn-submit"
                >
                  <span> Cancel</span>
                </Button>
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={isLoading || !isValid}
                  className="create-lesson-btn-submit"
                >
                  <span> {lessonId ? "Save" : "Create"} </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
      <CustomModal
        isOpen={deleteWordModal.isOpen}
        onClose={() =>
          setDeleteWordModal({ isOpen: false, dataType: "", rowIndex: null })
        }
        onConfirm={() => {
          if (deleteWordModal.dataType === TABLE_DATA_TYPES.WORDS) {
            setWordsData(
              removeRowFromTable(wordsData, deleteWordModal.rowIndex)
            );
          }
          if (deleteWordModal.dataType === TABLE_DATA_TYPES.SENTENCES) {
            setSentencesData(
              removeRowFromTable(sentencesData, deleteWordModal.rowIndex)
            );
          }
        }}
        confirmBtnText="Delete"
        confirmBtnClassname="btn-danger"
        title={`Delete ${
          deleteWordModal.dataType === TABLE_DATA_TYPES.WORDS
            ? "word"
            : "sentence"
        } `}
        type="question"
      >
        <p>
          Are you sure you want to delete the{" "}
          {deleteWordModal.dataType === TABLE_DATA_TYPES.WORDS
            ? "word"
            : "sentence"}
          ?{" "}
        </p>
      </CustomModal>
    </>
  );
};

export default Form;
