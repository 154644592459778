import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import VerticalLayout from "../../base/components/VerticalLayout";

import { USERS_GROUP_LINKS, USERS_GROUP_ROUTES } from "../users/config";
import {
  CONTENT_MANAGEMENT_GROUP_LINKS,
  CONTENT_MANAGEMENT_GROUP_ROUTES,
} from "../content/config";
import {
  ORGANIZATION_MANAGAMENT_GROUP_LINKS,
  ORGANIZATION_MANAGAMENT_GROUP_ROUTES,
} from "../organization/config";
import { REPORTS_GROUP_LINKS, REPORTS_GROUP_ROUTES } from "../reports/config";
import {
  NOTIFICATIONS_GROUP_LINKS,
  NOTIFICATIONS_GROUP_ROUTES,
} from "../notifications/config";

import Users from "../users";
import ContentManagement from "../content";
import OrganizationManagement from "../organization";
import Reports from "../reports";
import Notifications from "../notifications";

const AppRoutes = () => {
  const schema = useMemo(
    () => [
      {
        label: "Users Management",
        linkTo: USERS_GROUP_LINKS.BASE,
        icon: "bx bx-group",
      },
      {
        label: "Content Management",
        linkTo: CONTENT_MANAGEMENT_GROUP_LINKS.BASE,
        icon: "bx bx-spreadsheet",
      },
    ],
    []
  );

  return (
    <VerticalLayout schema={schema}>
      <Routes>
        <Route path={USERS_GROUP_ROUTES.BASE} element={<Users />} />
        <Route
          path={CONTENT_MANAGEMENT_GROUP_ROUTES.BASE}
          element={<ContentManagement />}
        />
        <Route
          path={ORGANIZATION_MANAGAMENT_GROUP_ROUTES.BASE}
          element={<OrganizationManagement />}
        />
        <Route path={REPORTS_GROUP_ROUTES.BASE} element={<Reports />} />
        <Route
          path={NOTIFICATIONS_GROUP_ROUTES.BASE}
          element={<Notifications />}
        />
        <Route
          path="*"
          element={<Navigate to={USERS_GROUP_LINKS.BASE} replace />}
        />
      </Routes>
    </VerticalLayout>
  );
};

export default AppRoutes;
