import Http from "./Http";
import { mockNetworkRequest } from "./interceptors/mockNetworkRequest";

class AuthService extends Http {
  static $displayName = "AuthService";

  async login({ email, password }) {
    return await this.post("/admins/sessions", {
      email,
      password,
    });
  }

  async logout() {
    return await this.delete("/sessions");
  }

  async register(values) {
    return await this.post("/users", values);
  }

  async registerLoginWithGoogle(token) {
    // TODO delete mocked role
    return await this.post("/socials/sessions/google", {
      token,
      role: "Trainer",
    });
  }

  async registerLoginWithFacebook(token) {
    // TODO delete mocked role
    return await this.post("/socials/sessions/facebook", {
      token,
      role: "Trainer",
    });
  }

  async sendRestorePasswordEmail({ email }) {
    return await this.post("/admins/verifications/password", { email });
  }

  async resetPassword(values) {
    return await this.patch("/verifications/password", values);
  }

  async checkLinkRelevance(values) {
    return await this.get("/admins/verifications/password", { params: values });
  }

  async checkValidToken(values) {
    return await this.post("/verifications/token/email", values);
  }

  async verifyUser(values) {
    return await this.put("/verifications/email", values);
  }

  async resendVerificationLink(values) {
    return await this.post("/verifications/email", values);
  }
}

export default AuthService;
