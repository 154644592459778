import React from "react";
import moment from "moment";
import cn from "classnames";
import {
  USER_PLAN_STATUS_ACTIVE_ID,
  USER_PLAN_STATUS_ACTIVE_TITLE,
  USER_PLAN_STATUS_CANCELED_ACTIVE_ID,
  USER_PLAN_STATUS_CANCELED_ACTIVE_TITLE,
  USER_PLAN_STATUS_PENDING_ID,
  USER_PLAN_STATUS_PENDING_TITLE,
  USER_PLAN_STATUS_UNACTIVE_ID,
  USER_PLAN_STATUS_UNACTIVE_TITLE,
  USER_PLANS_MAP,
  USER_STATUS_CREATED_ID,
  USER_STATUS_LOGGED_IN_ID,
  USER_STATUSES_MAP,
} from "../../../../base/constants/user";
import { formatDate } from "../../../../base/helpers/formatDate";

const DATE_FORMAT = "DD/MM/YYYY";

function getNameValue(firstName, lastName) {
  return `${firstName} ${lastName}`;
}

function getFirstLetter(string) {
  return string?.length ? string.slice(0, 1) : " ";
}

export const STATUSES_CLASSNAMES = {
  [USER_STATUS_CREATED_ID]: "status-created",
  [USER_STATUS_LOGGED_IN_ID]: "status-logged-in",
};

export const PLAN_STATUSES_CLASSNAMES = {
  [USER_PLAN_STATUS_ACTIVE_ID]: "status-active",
  [USER_PLAN_STATUS_UNACTIVE_ID]: "status-unactive",
  [USER_PLAN_STATUS_PENDING_ID]: "status-pending",
  [USER_PLAN_STATUS_CANCELED_ACTIVE_ID]: "status-canceled-active",
};

export const usersStructure = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email address",
    accessor: "email",
  },
  {
    Header: "Referral",
    accessor: "referral",
    Cell: ({ value }) => <div>{value || "-"}</div>,
  },
  {
    Header: "Date of registration",
    accessor: "createdAt",
    Cell: ({ value }) => <div>{formatDate(value, DATE_FORMAT) || "-"}</div>,
  },
  {
    Header: "Subscription plan",
    accessor: "subscription.durationType",
    Cell: ({ value }) => <div>{USER_PLANS_MAP[value] || "-"}</div>,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => (
      <div
        className={cn({
          [`${STATUSES_CLASSNAMES[value]} status `]: STATUSES_CLASSNAMES[value],
        })}
      >
        {value ? USER_STATUSES_MAP[value] : "-"}
      </div>
    ),
  },
];

export const userInfoTitles = {
  status: "Status",
  firstName: "First Name",
  lastName: "Last name",
  email: "Email address",
  createdAt: "Date of registration",
  referral: "Referral",
  role: "Role",
  durationType: "Subscription Plan",
};
