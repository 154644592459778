import React, {useEffect, useRef, useState} from "react";

import useOnScreen from "../hooks/useOnScreen";

const LessonsListWrapper = ({ children, onEndScroll }) => {
  const [ isLoading, setIsLoading ] = useState(false);

  const refEndDiv = useRef();
  const isVisible = useOnScreen(refEndDiv)

  useEffect(() => {
    if (isVisible && !isLoading) {
      setIsLoading(true);
      onEndScroll().finally(() => setIsLoading(false));
    }
  }, [isVisible])

  return (
    <div
      style={{maxHeight: 605, overflowY: "auto", overflowX: "hidden"}}
      className="lesson-list lesson-list-scrollbar"
    >
      {children}
      <div ref={refEndDiv}/>
    </div>
  )
}

export default LessonsListWrapper;