import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: false,
};

class ToasterService {

    success(message) {
        toast.success(message, options)
    }

    error(message) {
        toast.error(message)
    }

    static $displayName = "ToasterService"
}


export default ToasterService
