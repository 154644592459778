
export const COMPLETE_PROFILE_GROUP_ROUTES = {
	BASE: '/complete-profile/*',
	EXTERNAL: {

	}
};

export const COMPLETE_PROFILE_GROUP_LINKS = {
	BASE: '/complete-profile',
	EXTERNAL: {

	}
};