export const MAX_FILE_SIZE = 31_457_280; // 30MB

export const ALLOWED_EXTENTIONS_FILE = ["image/jpeg", "image/png"];

export const ERROR_SIZE_MESSAGE = "File is too big. Maximum image size is 30Mb";
export const ERROR_ALLOWED_EXTENTION_MESSAGE =
  "Image format is invalid. Please select JPG, JPEG or PNG file";

export const TYPES = {
  IMAGE: "image",
};
