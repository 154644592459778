import React from "react";
import FormWizard from "./components/FormWizard";

import MainInfo from "./pages/MainInfo";
import Avatar from "./pages/Avatar";
import AboutYourself from "./pages/AboutYourself";

const completeProfileSteps = [
  {
    id: 1,
    Component: MainInfo,
    label: "Main info",
  },
  {
    id: 2,
    Component: Avatar,
    label: "M",
  },
  {
    id: 3,
    Component: AboutYourself,
    label: "Masd",
  },
];

const CompleteProfile = () => {
  return (
    <div className="page-content">
      <FormWizard steps={completeProfileSteps} label="Complete profile" />
    </div>
  );
};

export default CompleteProfile;
