import React, { useEffect } from "react";
import PageMeta from "../../base/components/MetaTags";

const Reports = () => {
  return (
    <>
      <PageMeta title="Reports" />

      <div className="page-content ">
        <h3>Reports</h3>
      </div>
    </>
  );
};

export default Reports;
