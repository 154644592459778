import {KeyboardSensor, PointerSensor, TouchSensor, useSensor} from "@dnd-kit/core";
import {arrayMove, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import {useCallback} from "react";
import {LESSON_SORT_PROPERTY} from "../../../../../base/constants/lesson";

export const deleteEntity = (removeId, array) => array.filter(({ id }) => id !== removeId );

export const lessonNumberByPlace = (array) => [...array.map((item, index) => ({...item, [LESSON_SORT_PROPERTY]: index + 1}))];

export const preformNewOrder = (array, updatedIndex) => {
  return {
    id: array?.[updatedIndex]?.id,
    body: {
      isFirstLesson: updatedIndex === 0,
      ...(updatedIndex > 0 && {previousLessonId: array?.[updatedIndex - 1]?.id }),
      isLastLesson: updatedIndex >= (array.length - 1),
      ...(updatedIndex < (array.length - 1) && { nextLessonId: array?.[updatedIndex + 1]?.id }),
    }
  };
}

export const createHandleSortEnd = ({setData, setUpdatedElementIndex}) => {
  return  useCallback((event) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        setUpdatedElementIndex(newIndex);

        return [ ...arrayMove(items, oldIndex, newIndex)];
      });
    }
  },[setData, setUpdatedElementIndex]);
}

export const getPointerSensor = () => useSensor(PointerSensor, {
  // Press delay of 250ms, with tolerance of 5px of movement
  activationConstraint: {
    tolerance: 10,
  }
});
export const getKeyboardSensor = () => useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });
export const getTouchSensor = () => useSensor(TouchSensor, {
  // Press delay of 250ms, with tolerance of 5px of movement
  activationConstraint: {
    delay: 250,
    tolerance: 5,
  },
});

