import React from "react";

import FormikDropzone from "../../../../../../../base/components/FormikDropZone";
import { XSL_FORMATS } from "../../../../../../../base/constants/fileFormats";
import Table from "../../../../../../../base/components/Table";
import Button from "../../../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../../../base/components/Button/appearance";
import DropzoneFile from "../../../../../../../features/DropzoneFile";

export default function XslTable({
  name,
  onDrop,
  tableData,
  columnData,
  onAddMore,
  onDelete,
  onUpdateRow,
  onRemoveRow,
}) {
  const AddButton = () => (
    <Button
      color={BUTTON_COLORS.primary}
      outlined
      className="create-lesson-btn"
    >
      Add more
    </Button>
  );
  return (
    <>
      {!tableData.length ? (
        <FormikDropzone
          name={name}
          title="Drop .xls, .xlsx files  here or click to upload."
          className="file-dropzone"
          onChange={onDrop}
          acceptedFormats={XSL_FORMATS}
        />
      ) : (
        <div className="d-flex flex-column">
          <Table
            data={tableData}
            columns={columnData}
            isRowClick={false}
            tableClassname="p-0 my-4 lessons-table"
            isPagination={false}
            onUpdateData={onUpdateRow}
            onRemoveRow={onRemoveRow}
          />
          <div className="d-flex align-self-end gap-2 mt-5">
            <Button
              color={BUTTON_COLORS.secondary}
              outlined
              onClick={onDelete}
              className="create-lesson-btn"
            >
              <span> Delete</span>
            </Button>
            <DropzoneFile
              className="mt-0 p-0 border-0"
              isDropContainer={false}
              ButtonComponent={AddButton}
              onReciveFile={onAddMore}
            ></DropzoneFile>
          </div>
        </div>
      )}
    </>
  );
}
