import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import TopNavigation from "./TopNavigation";
import BottomNavigation from "./BottomNavigation";
import FormContent from "./FormContent";
import PropTypes from "prop-types";
import { useWizardForms } from "./useFormWizard";
import BasicInfo from "./BasicInfo";

//TODO delete IDs
const STEPS = [
  { id: 1, Component: BasicInfo, label: "first" },
  { id: 2, Component: () => <>2</>, label: "second" },
  { id: 3, Component: () => <>3</>, label: "third" },
  { id: 4, Component: () => <>4</>, label: "forth" },
];

export default function FormWizard({ steps = STEPS, label = "Basic Wizard" }) {
  const {
    onFormChanges,
    onClickNext,
    onClickPrev,
    getInitialValues,
    getCurrentValues,
  } = useWizardForms({
    steps: STEPS,
    onSubmit: async () => {
      //console.log(values);
    },
  });

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-5">{label}</h4>
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <div className="mt-2">
                  <FormContent
                    steps={steps}
                    onFormChanges={onFormChanges}
                    getInitialValues={getInitialValues}
                    getCurrentValues={getCurrentValues}
                    TopNavigation={TopNavigation}
                  />
                </div>
                <BottomNavigation
                  steps={steps}
                  onClickNext={onClickNext}
                  onClickPrev={onClickPrev}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

FormWizard.propTypes = {
  steps: PropTypes.array,
  label: PropTypes.string,
};
