import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

export default function RadioButton({ text, value, onChange, id, ...props }) {
  return (
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id={id}
        onChange={onChange}
        {...props}
      />
      <label className="form-check-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  text: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.number,
};
