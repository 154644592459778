const BASE = "/app/users";

export const USER_INFO_GROUP_LINKS = {
  BASE,
  LINK_USER_EDIT: "/edit",
};

export const USER_INFO_GROUP_ROUTES = {
  BASE: "/*",
  LINK_USER_EDIT: "/edit",
};
