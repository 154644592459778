import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Formik } from "formik";
import { Toaster } from "react-hot-toast";

import FormikInput from "../../../base/components/FormikInput";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import { useToaster } from "../../../base/hooks/useToaster";

import AuthService from "../../../services/AuthService";

import { RESET_PASSWORD_RESEND_SUCCESS_MESSAGE } from "../../../base/constants/messages";
import STATUS_CODE from "../../../base/constants/statusCodes";
import { FORGOT_PASSWORD_MESSAGES } from "../../../base/constants/statusMessages";
import { MAX_EMAIL_LENGTH } from "../../../validation/lengthConstants";

import errorMail from "../assets/error-mail.svg";
import successMail from "../assets/success-mail.svg";

import { AUTH_GROUP_LINKS } from "../config";

import { initialValues, validationSchema } from "../pages/ForgetPassword/form";

const MAX_COUNT_TO_RESENT = 5;
const TIMER_INTERVAL = 1000;
const SECONDS_TO_WAIT = 60;
const ONE_MILLISEC = 1000;
const SECONDS_FORMAT = "mm:ss";

export default function ExpiredLink({ errorCode }) {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const [isLoading, { registerPromise }] = useLoading();
  const navigate = useNavigate();
  const { displayToaster } = useToaster();

  const [isLinkSent, setIsLinkSent] = useState(false);
  const [errorStatusCode, setErrorStatusCode] = useState(errorCode || 0);

  const [seconds, setSeconds] = useState(SECONDS_TO_WAIT);

  const getFormattedSecond = (seconds) =>
    moment.utc(seconds * ONE_MILLISEC).format(SECONDS_FORMAT);

  const isSubmitBtnDisabled = (errors) => {
    if (errorStatusCode === STATUS_CODE.LIMIT) {
      return false;
    }
    if (isLinkSent && !!seconds) {
      return true;
    }
    if (isLoading || Object.keys(errors).length) {
      return true;
    }

    return false;
  };

  const resendLink = (email, setErrors) => {
    registerPromise(authService.sendRestorePasswordEmail(email))
      .then(() => {
        displayToaster(RESET_PASSWORD_RESEND_SUCCESS_MESSAGE);
        setIsLinkSent(true);
      })
      .catch((error) => {
        setErrors({
          email: FORGOT_PASSWORD_MESSAGES[error.statusCode],
        });
        setErrorStatusCode(error.statusCode);
        setIsLinkSent(false);
      });
  };

  useEffect(() => {
    let interval = null;
    if (isLinkSent) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, TIMER_INTERVAL);
    }
    if (seconds == 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isLinkSent, seconds]);

  return (
    <>
      <SweetAlert
        title={isLinkSent ? "Success!" : "Expired!"}
        custom
        customIcon={isLinkSent ? successMail : errorMail}
        hideOverlay
        showConfirm={false}
      >
        {isLinkSent && (
          <span>Restoration link was successfully sent to specified email</span>
        )}
        {!isLinkSent && (
          <span>
            {errorStatusCode === STATUS_CODE.LIMIT
              ? `You can resend a link only ${MAX_COUNT_TO_RESENT} times per 24h. Try again tomorrow.`
              : "This link is expired"}
          </span>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            if (isLinkSent) {
              setSeconds(SECONDS_TO_WAIT);
            }

            if (errorStatusCode === STATUS_CODE.LIMIT) {
              navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
            } else {
              resendLink(values, setErrors);
            }
          }}
        >
          {({ errors, handleSubmit }) => (
            <form className="form-horizontal " onSubmit={handleSubmit}>
              {errorStatusCode !== STATUS_CODE.LIMIT && (
                <FormikInput
                  id="email"
                  name="email"
                  containerClassName="mb-4 input-container text-left"
                  placeholder="Enter email"
                  type="email"
                  maxLength={MAX_EMAIL_LENGTH}
                />
              )}

              <button
                className={cn(
                  "btn btn-primary btn-block  waves-effect waves-light mt-2",
                  { ["w-25"]: errorStatusCode === STATUS_CODE.LIMIT },
                  {
                    ["w-50"]:
                      errorStatusCode !== STATUS_CODE.LIMIT || isLinkSent,
                  }
                )}
                type="submit"
                onClick={() => {
                  if (errorStatusCode === STATUS_CODE.LIMIT) {
                    navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
                  }
                }}
                disabled={isSubmitBtnDisabled(errors)}
              >
                {isLinkSent && <span> Resend restoration link</span>}
                {!isLinkSent && (
                  <span>
                    {errorStatusCode === STATUS_CODE.LIMIT
                      ? "Ok"
                      : "Resend restoration link"}
                  </span>
                )}
              </button>
              {!!seconds &&
                errorStatusCode !== STATUS_CODE.LIMIT &&
                isLinkSent && (
                  <p> Resend link in {getFormattedSecond(seconds)} sec</p>
                )}
            </form>
          )}
        </Formik>
      </SweetAlert>
      <Toaster />
    </>
  );
}
