import React from "react";
import { Route, Routes } from "react-router-dom";

import EditUserInfo from "./pages/EditInfo";
import Info from "./pages/Info";

import { USER_INFO_GROUP_ROUTES } from "./config";

const UserInfo = () => {
  return (
    <Routes>
      <Route path={USER_INFO_GROUP_ROUTES.BASE} element={<Info />} />
      <Route
        path={USER_INFO_GROUP_ROUTES.LINK_USER_EDIT}
        element={<EditUserInfo />}
      />
    </Routes>
  );
};

export default UserInfo;
