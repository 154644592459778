import * as yup from "yup";
import { USER_EMAIL } from "../../../../validation/user";
import { VALIDATION_ERRORS } from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required(VALIDATION_ERRORS.email.required),
});

export const initialValues = {
  email: "",
};
