import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { CustomModal } from "../../../auth/components/Modal";
import UserInfoForm from "../UserInfoForm";

import { MODAL_TYPES } from "../../../../base/constants/modal";

import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import { useToaster } from "../../../../base/hooks/useToaster";

import UsersService from "../../../../services/UsersService";

import { TOASTER_TYPES } from "../../../../base/constants/toaster";

import { initialValues } from "./form";
import { validationSchema } from "./form";

const TITLE = "Create a new User";

export default function CreateUserModal({
  isOpen,
  onClose,
  handleTableChange,
}) {
  /**
   * @type {UsersService}
   */
  const userService = useService(UsersService);
  const [isLoading, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();

  const createUser = useCallback(
    (values) => {
      registerPromise(
        userService.createUser({
          ...values,
          durationType: +values.durationType || undefined,
        })
      )
        .then(() => {
          onClose();
          handleTableChange();
        })
        .catch((error) => {
          if (error.message.isArray) {
            error.message.forEach((error) =>
              displayToaster(error, TOASTER_TYPES.ERROR)
            );
          } else {
            displayToaster(error.message, TOASTER_TYPES.ERROR);
          }
        });
    },
    [registerPromise, userService]
  );

  return (
    <CustomModal
      isOpen={isOpen}
      title={TITLE}
      type={MODAL_TYPES.QUESTION}
      isCustomFooter
      onClose={onClose}
    >
      <UserInfoForm
        onCancel={onClose}
        onSubmit={createUser}
        submitBtnText="Create"
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
    </CustomModal>
  );
}

CreateUserModal.propTypes = { isOpen: PropTypes.bool, onClose: PropTypes.func };
