import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { Card } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useToaster } from "../../../../base/hooks/useToaster";

import UsersService from "../../../../services/UsersService";

import Table from "../../../../base/components/Table";
import UsersTableHeader from "./components/TableHeader";

import { usersStructure } from "./tableStructure";

import { TOASTER_TYPES } from "../../../../base/constants/toaster";
import "./index.scss";
const USERS_LIMIT = 12;
const TIME_TO_DEBOUNCE = 600;

export const UsersList = () => {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  const [isLoading, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();
  const navigate = useNavigate();

  const [users, setUsers] = useState({ data: [], pagination: {} });
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});

  const getUsers = useCallback(
    (pageNumber = 1, search = "", filters = {}) => {
      registerPromise(
        usersService.getUsers({
          limit: USERS_LIMIT,
          offset: (pageNumber - 1) * USERS_LIMIT,
          search: search || undefined,
          ...filters,
        })
      )
        .then((data) => {
          setUsers(data);
        })
        .catch((error) => displayToaster(error.message, TOASTER_TYPES.ERROR));
    },
    [registerPromise, usersService]
  );

  const onApplyFilter = useCallback(
    (filterStatus = undefined, subscriptionPlanFilter = undefined) => {
      setFilters({ filterStatus, filterDurationType: subscriptionPlanFilter });

      getUsers(1, search, {
        filterStatus,
        filterDurationType: subscriptionPlanFilter,
      });
    },
    [users, search]
  );

  const onRowClick = useCallback(
    (row) => {
      const userId = row.original.id;
      navigate(`${location.pathname}/${userId}`);
    },
    [users]
  );

  const debounceSearch = useCallback(
    debounce(
      (search, filters) => getUsers(1, search, filters),
      TIME_TO_DEBOUNCE
    ),
    []
  );

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="page-content ">
      <section className="user-list">
        <h3 className="mb-4 lessons-page-header">Users Management</h3>
        <Card>
          <div className="my-4">
            <Table
              data={users.data}
              columns={usersStructure}
              loading={isLoading}
              emptyLabel="No Users yet"
              HeaderComponent={UsersTableHeader}
              onSearchChange={({ target: { value } }, filters) => {
                setSearch(value);
                debounceSearch(value, filters);
              }}
              searchField={search}
              isRowClick
              filters={filters}
              onRowClick={onRowClick}
              limit={USERS_LIMIT}
              offset={users.pagination.offset || 0}
              totalCount={users.pagination.totalCount}
              handleTableChange={getUsers}
              onApplyFilter={onApplyFilter}
              paginationOffset={users.pagination.offset}
              paginationLimit={USERS_LIMIT}
            />
          </div>
        </Card>
      </section>
    </div>
  );
};
