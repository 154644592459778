import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useField } from "formik";
import cn from "classnames";

import DropzoneFile from "../../features/DropzoneFile/index";
import S3Service from "../../services/S3Service";
import { useService } from "../hooks/useService";
import { TYPES } from "../../features/DropzoneFile/constants/dropzoneConstants";
import { validateImage } from "../../validation/validateImageFile";

const FormikDropzone = ({
  type,
  name,
  label,
  className,
  title,
  onChange,
  acceptedFormats,
  initialFile,
  initialFileId,
}) => {
  const [{ value }, { error, touched }, { setValue, setError }] = useField({
    name,
  });
  const [currentFile, updateCurrentFile] = useState({
    link: initialFile,
    id: initialFileId,
  });
  const imageService = useService(S3Service);

  const currentFileRef = useRef();
  currentFileRef.current = currentFile;

  const handleChange = useCallback(
    async (file) => {
      const currentFileId = currentFileRef.current?.id;
      if (initialFileId && currentFileId === initialFileId) {
        await imageService.deleteFile(currentFileId);
      }

      const data = await imageService.uploadImageFile(file);
      const fileUrl = data.link;
      const fileId = data.id;

      updateCurrentFile({ link: fileUrl, id: fileId });

      if (!fileId) return;
      setValue(fileId);
    },
    [updateCurrentFile, value, currentFile]
  );

  const onValidate = (file) => validateImage(file, setError);

  const handleRemoveFile = useCallback(async () => {
    try {
      await imageService.deleteFile(value);
    } catch (error) {
      console.log(error);
    }
    updateCurrentFile(null);
    setValue(null);
  }, [value, updateCurrentFile, setValue, imageService]);

  return (
    <>
      <section className="">
        {label && (
          <label
            htmlFor={name}
            className={classNames({ "text-danger": error && touched })}
          >
            {label}
          </label>
        )}
        <DropzoneFile
          onReciveFile={type === TYPES.IMAGE ? handleChange : onChange}
          className={className}
          title={title}
          onRemove={handleRemoveFile}
          onValidate={onValidate}
          fileUrl={currentFile?.link}
          acceptedFormats={acceptedFormats}
          type={type}
        />
        {error && touched && (
          <span className={cn("invalid-textarea", "invalid-input")}>
            {error}
          </span>
        )}
      </section>
    </>
  );
};

FormikDropzone.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onSelectedFile: PropTypes.func,
  acceptedFormats: PropTypes.object,
};

export default FormikDropzone;
