export const USER_PLAN_MONTHLY_ID = 1;
export const USER_PLAN_ANNUAL_ID = 2;
export const USER_PLAN_1_MONTH_ID = 3;
export const USER_PLAN_LIFETIME_ID = 4;
export const USER_PLAN_BETA_TESTER_ID = 5;
export const USER_PLAN_BULK_ID = 6;
export const USER_PLAN_NONE_ID = 0;

export const USER_PLAN_MONTHLY_TITLE = "Monthly";
export const USER_PLAN_ANNUAL_TITLE = "Annual";
export const USER_PLAN_1_MONTH_TITLE = "Free trial (1 month)";
export const USER_PLAN_LIFETIME_TITLE = "Free (lifetime)";
export const USER_PLAN_BETA_TESTER_TITLE = "Free (beta tester)";
export const USER_PLAN_BULK_TITLE = "Bulk";
export const USER_PLAN_NONE_TITLE = "None";

export const USER_STATUS_CREATED_TITLE = "Created";
export const USER_STATUS_LOGGED_IN_TITLE = "Logged in";

export const USER_STATUS_CREATED_ID = 1;
export const USER_STATUS_LOGGED_IN_ID = 2;

export const USER_PLAN_STATUS_ACTIVE_ID = 1;
export const USER_PLAN_STATUS_UNACTIVE_ID = 2;
export const USER_PLAN_STATUS_PENDING_ID = 3;
export const USER_PLAN_STATUS_CANCELED_ACTIVE_ID = 4;

export const USER_PLAN_STATUS_ACTIVE_TITLE = "Active";
export const USER_PLAN_STATUS_UNACTIVE_TITLE = "Unactive";
export const USER_PLAN_STATUS_PENDING_TITLE = "Pending";
export const USER_PLAN_STATUS_CANCELED_ACTIVE_TITLE = "Canceled active";

export const USER_STATUSES = [
  { id: USER_STATUS_CREATED_ID, title: USER_STATUS_CREATED_TITLE },
  { id: USER_STATUS_LOGGED_IN_ID, title: USER_STATUS_LOGGED_IN_TITLE },
];

export const USER_STATUSES_MAP = {
  [USER_STATUS_CREATED_ID]: USER_STATUS_CREATED_TITLE,
  [USER_STATUS_LOGGED_IN_ID]: USER_STATUS_LOGGED_IN_TITLE,
};

export const PLANS = [
  { id: USER_PLAN_1_MONTH_ID, title: USER_PLAN_1_MONTH_TITLE },
  { id: USER_PLAN_BETA_TESTER_ID, title: USER_PLAN_BETA_TESTER_TITLE },
  { id: USER_PLAN_LIFETIME_ID, title: USER_PLAN_LIFETIME_TITLE },
  { id: USER_PLAN_BULK_ID, title: USER_PLAN_BULK_TITLE },
];

export const USER_PLANS_MAP = {
  [USER_PLAN_MONTHLY_ID]: USER_PLAN_MONTHLY_TITLE,
  [USER_PLAN_ANNUAL_ID]: USER_PLAN_ANNUAL_TITLE,
  [USER_PLAN_1_MONTH_ID]: USER_PLAN_1_MONTH_TITLE,
  [USER_PLAN_LIFETIME_ID]: USER_PLAN_LIFETIME_TITLE,
  [USER_PLAN_BETA_TESTER_ID]: USER_PLAN_BETA_TESTER_TITLE,
  [USER_PLAN_BULK_ID]: USER_PLAN_BULK_TITLE,
};

export const USER_PLANS_STATUSES_MAP = {
  [USER_PLAN_STATUS_ACTIVE_ID]: USER_PLAN_STATUS_ACTIVE_TITLE,
  [USER_PLAN_STATUS_UNACTIVE_ID]: USER_PLAN_STATUS_UNACTIVE_TITLE,
  [USER_PLAN_STATUS_PENDING_ID]: USER_PLAN_STATUS_PENDING_TITLE,
  [USER_PLAN_STATUS_CANCELED_ACTIVE_ID]: USER_PLAN_STATUS_CANCELED_ACTIVE_TITLE,
};
