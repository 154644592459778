import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import LessonItem from "./LessonItem";
import React from "react";

const LessonItemSortable = ({ avatar, title, description, lessonName, id, onDelete, onEdit, allowDrag }) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id } );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <LessonItem
        avatar={avatar}
        id={id}
        title={title}
        lessonName={lessonName}
        description={description}
        onEdit={onEdit}
        onDelete={onDelete}
        allowDrag={allowDrag}
        sortableProps={{
          attributes,
          listeners
        }}
      />
    </div>
  )
};

export default LessonItemSortable;