import React, { useEffect } from "react";
import PageMeta from "../../base/components/MetaTags";

const OrganizationManagement = () => {
  return (
    <>
      <PageMeta title="Organization management" />

      <div className="page-content ">
        <h3>Organization Management</h3>
      </div>
    </>
  );
};

export default OrganizationManagement;
