import React, { useEffect, useState } from "react";

export default function EditableCell({
  value: initialValue,
  row: { index },
  column: { id },
  onUpdateData,
  editableRowIndex,
}) {
  const [cellValue, setCellValue] = useState(initialValue);

  const onBlur = () => {
    onUpdateData(index, id, cellValue);
  };

  useEffect(() => {
    setCellValue(initialValue);
  }, [initialValue]);

  return index === editableRowIndex ? (
    <input
      value={cellValue}
      onChange={({ target: { value } }) => setCellValue(value)}
      onBlur={onBlur}
      className="table-input"
    />
  ) : (
    <span>{cellValue}</span>
  );
}
