import * as yup from "yup";
import { USER_EMAIL, USER_NAME } from "../../../../validation/user";
import { VALIDATION_ERRORS } from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  firstName: USER_NAME.required(VALIDATION_ERRORS.firstName.required),
  lastName: USER_NAME.required(VALIDATION_ERRORS.lastName.required),
  email: USER_EMAIL.required(VALIDATION_ERRORS.email.required),
  durationType: yup.string().required(VALIDATION_ERRORS.plan.required),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  durationType: "",
};
