import Http from "./Http";

class UsersService extends Http {
  static $displayName = "UsersService";

  async getUsers(params) {
    return await this.get(`/admins/users`, { params });
  }

  async getUserById(id) {
    return await this.get(`/admins/users/${id}`);
  }

  async deleteUserById(id) {
    return await this.delete(`/admins/users/${id}`);
  }

  async updateUser(id, values) {
    await this.patch(`/admins/users/${id}`, values);
  }

  async createUser(values) {
    return await this.post("/admins/users", values);
  }
}

export default UsersService;
