import React, { useCallback, useState } from "react";
import { CardBody, Card, Alert, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import FormikInput from "../../../../base/components/FormikInput";
import { ErrorMessage, Field, Formik } from "formik";
import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import { useLoading } from "../../../../base/hooks/useLoading";
import StorageService from "../../../../services/StorageService";
import SessionStorage from "../../../../services/SessionStorage";
import Button from "../../../../base/components/Button/index";
import {
  BUTTON_COLORS,
  BUTTON_STYLES,
} from "../../../../base/components/Button/appearance";
import { initialValues, validationSchema } from "./form";
import { AUTH_GROUP_LINKS } from "../../config";
import { CustomModal } from "../../components/Modal";
import {
  MAX_EMAIL_LENGTH,
  MAX_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";
import { SIGN_IN_MESSAGES } from "../../../../base/constants/statusMessages";

const Login = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);

  const navigate = useNavigate();

  const [alertText, { onCatchError }] = useRequestErrorMessage();
  const [isLoading, { registerPromise }] = useLoading();

  const [isBlockedModalOpened, setIsBlockedModalOpened] = useState(false);

  const loginUser = useCallback(
    (values, setErrors) => {
      registerPromise(authService.login(values))
        .then((session) => {
          storageSession.setSession(session);
          navigate(AUTH_GROUP_LINKS.EXTERNAL.AFTER_LOGIN);
        })
        .catch((error) => {
          setErrors({
            email: SIGN_IN_MESSAGES[error.statusCode],
          });
        });
    },
    [registerPromise, authService, storageSession, storage, navigate]
  );

  return (
    <AuthCardContainer metaText="Login">
      <Card className="overflow-hidden auth-card">
        <AuthHeader headerText="Sign in to Cognate Admin panel" />

        <CardBody className="pt-0">
          <Logo />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors }) => {
              loginUser(values, setErrors);
            }}
          >
            {({ errors, handleSubmit }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                {alertText ? <Alert color="danger">{alertText}</Alert> : null}
                <FormikInput
                  id="email"
                  name="email"
                  label="Email"
                  containerClassName="mb-3 input-container"
                  placeholder="Enter email"
                  type="email"
                  maxLength={MAX_EMAIL_LENGTH}
                />
                <FormikInput
                  id="password"
                  label="Password"
                  name="password"
                  containerClassName="mb-3 input-container"
                  type="password"
                  placeholder="Enter password"
                  as="password"
                  maxLength={MAX_PASSWORD_LENGTH}
                />

                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  block={BUTTON_STYLES.block}
                  className="mt-3"
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  Sign In
                </Button>
                <Row className="mt-4 text-center">
                  <Link
                    to={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
                    className="text-muted d-flex align-items-center justify-content-center"
                  >
                    <i className="mdi mdi-lock me-2 font-size-24" />
                    Forgot your password?
                  </Link>
                </Row>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
      <CustomModal
        isOpen={isBlockedModalOpened}
        onClose={() => setIsBlockedModalOpened(false)}
        title="Blocked account"
      >
        <p>
          You have been blocked by the Admin. Currently, you don’t have access
          to the account. Contact us if you have any questions.
        </p>
      </CustomModal>
    </AuthCardContainer>
  );
};

export default Login;
