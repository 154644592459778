import React from "react";
import { Formik } from "formik";

import FormikInput from "../../../../base/components/FormikInput";
import FormikSelect from "../../../../base/components/FormikSelect";

import {
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
} from "../../../../validation/lengthConstants";
import {
  PLANS,
  USER_PLAN_NONE_ID,
  USER_PLAN_NONE_TITLE,
} from "../../../../base/constants/user";
import cn from "classnames";

export default function UserInfoForm({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  submitBtnText,
  isEdit,
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validateOnMount={true}
    >
      {({ errors, handleSubmit, isValid, dirty }) => (
        <form className="form-horizontal p-2" onSubmit={handleSubmit}>
          <FormikInput
            id="firstName"
            name="firstName"
            label="First Name"
            containerClassName="mb-3 input-container"
            placeholder="Enter first name"
            type="text"
            maxLength={MAX_NAME_LENGTH}
          />
          <FormikInput
            id="lastName"
            name="lastName"
            label="Last Name"
            containerClassName="mb-3 input-container"
            placeholder="Enter last name"
            type="text"
            maxLength={MAX_NAME_LENGTH}
          />
          <FormikInput
            id="email"
            name="email"
            label="Email address"
            containerClassName="mb-3 input-container"
            placeholder="Enter email address"
            type="email"
            maxLength={MAX_EMAIL_LENGTH}
          />
          <FormikSelect
            id="plan"
            name="durationType"
            label="Subscription Plan"
            containerClassName="mb-3 input-container"
          >
            <option disabled={true} value="">
              Choose subscription Plan
            </option>
            {PLANS.map(({ title, id }, index) => (
              <option value={id} key={index}>
                {title}
              </option>
            ))}
            <option value={USER_PLAN_NONE_ID}>{USER_PLAN_NONE_TITLE}</option>
          </FormikSelect>

          <div className={cn("modal-footer", { "border-0": isEdit })}>
            <button
              type="button"
              onClick={onCancel}
              className="btn btn-outline-secondary w-xs"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary w-xs"
              disabled={!isValid}
            >
              {submitBtnText}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

UserInfoForm.propTypes = {};
