import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { UncontrolledAlert } from "reactstrap";

import {
  TOASTER_COLORS,
  TOASTER_ICONS_CLASSNAME,
  TOASTER_TYPES,
} from "../constants/toaster";

const CustomToast = ({ msg, type }) => {
  const toasterIconClassname = TOASTER_ICONS_CLASSNAME[type];

  return (
    <UncontrolledAlert
      role="alert"
      color={TOASTER_COLORS[type]}
      className="custom-alert mr-3"
    >
      {toasterIconClassname && <i className={toasterIconClassname}></i>}
      {msg}
    </UncontrolledAlert>
  );
};

export function useToaster() {
  const displayToaster = (
    msg = "Something went wrong...",
    type = TOASTER_TYPES.SUCCESS
  ) => {
    toast.custom(<CustomToast msg={msg} type={type} />, {
      position: "top-right",
    });
  };

  return { displayToaster };
}
