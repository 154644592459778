import * as yup from "yup";

import { USER_PASSWORD } from "../../../../validation/user";
import { VALIDATION_ERRORS } from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  password: USER_PASSWORD.required(VALIDATION_ERRORS.password.required),
  repeatPassword: USER_PASSWORD.required(
    VALIDATION_ERRORS.password.required
  ).test("passwords-match", VALIDATION_ERRORS.password.match, function (value) {
    return this.parent.password === value;
  }),
});

export const initialValues = {
  password: "",
  repeatPassword: "",
};
