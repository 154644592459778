import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
// Redux
import adminAvatar from "../../../../assets/images/users/admin-avatar.svg";
// users
import { Link } from "react-router-dom";
import { PROFILE_GROUP_ROUTES } from "../../../../groups/profile/config";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={adminAvatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">Admin</span>
          <i
            className={`mdi mdi-chevron-${
              menu ? "up" : "down"
            } d-none d-xl-inline-block`}
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/auth/logout" className="dropdown-item">
            <i className="bx bx-log-out font-size-16 align-middle me-1 " />
            <span>Log out</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default ProfileMenu;
