import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, CardTitle, Col, Row, Table } from "reactstrap";

import { CustomModal } from "../../../../auth/components/Modal";
import { useUserIdFromParams } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { USER_INFO_GROUP_LINKS } from "../config";
import { useService } from "../../../../../base/hooks/useService";
import UsersService from "../../../../../services/UsersService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { USERS_GROUP_LINKS } from "../../../config";
import { useToaster } from "../../../../../base/hooks/useToaster";
import {
  DELETE_USER_SUCCESS_MESSAGE,
  RESET_PASSWORD_RESEND_SUCCESS_MESSAGE,
} from "../../../../../base/constants/messages";
import {
  USER_PLAN_ANNUAL_ID,
  USER_PLAN_MONTHLY_ID,
  USER_STATUS_LOGGED_IN_ID,
} from "../../../../../base/constants/user";

const UserActions = ({ user }) => {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);

  const { userId } = useUserIdFromParams();
  const [, { registerPromise }] = useLoading();
  const navigate = useNavigate();
  const { displayToaster } = useToaster();

  const { status, subscription } = user;

  const isEditButtonShown =
    status !== USER_STATUS_LOGGED_IN_ID &&
    subscription?.durationType !== USER_PLAN_MONTHLY_ID &&
    subscription?.durationType !== USER_PLAN_ANNUAL_ID;

  const deleteUser = useCallback(() => {
    registerPromise(usersService.deleteUserById(userId)).then(() => {
      navigate(USERS_GROUP_LINKS.BASE);
      displayToaster(DELETE_USER_SUCCESS_MESSAGE);
    });
  }, [registerPromise, usersService, userId]);

  const linkToEditPage = `${USER_INFO_GROUP_LINKS.BASE}/${userId}${USER_INFO_GROUP_LINKS.LINK_USER_EDIT}`;

  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  return (
    <>
      <Col className="d-flex justify-content-end">
        {isEditButtonShown && (
          <Button
            outline
            color="primary"
            className=" d-flex align-items-center "
            size="xs"
            onClick={() => navigate(linkToEditPage)}
          >
            <i className="mdi mdi-pencil font-size-16" />
            <span className="ms-2">Edit</span>
          </Button>
        )}

        <Button
          outline
          color="danger"
          className=" d-flex align-items-center mx-3"
          size="xs"
          onClick={() => setIsDeleteModalShown(true)}
        >
          <i className="mdi mdi-trash-can font-size-16" />
          <span className="ms-2">Delete</span>
        </Button>
      </Col>

      <CustomModal
        isOpen={isDeleteModalShown}
        onClose={() => setIsDeleteModalShown(false)}
        onConfirm={deleteUser}
        confirmBtnText="Delete"
        confirmBtnClassname="btn-danger"
        title="Deleted user"
        type="question"
      >
        <p>Are you sure you want to delete this User?</p>
      </CustomModal>
    </>
  );
};

UserActions.propTypes = {
  user: PropTypes.object,
};

export default UserActions;
