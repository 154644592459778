import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useField } from "formik";
import cn from "classnames";

import subscriptIcon from "./assets/subscript-icon.svg";

import { useService } from "../../hooks/useService";
import S3Service from "../../../services/S3Service";
import { validateImage } from "../../../validation/validateImageFile";

import "./index.scss";

const icons = ReactQuill.Quill.import("ui/icons");
icons["link"] = `<img src=${subscriptIcon} class="fm_editor_icon">`;

const formats = [
  "bold",
  "italic",
  "underline",
  "image",
  "color",
  "link",
  "header",
];

const FormikTextEditor = ({ placeholder, name, containerClassname }) => {
  const [field, meta, helpers] = useField({ name });
  const quillRef = useRef();
  const inputRef = useRef();

  const imageService = useService(S3Service);

  const { onBlur, value } = field;
  const { touched, error } = meta;
  const { setValue, setError } = helpers;

  const handleChange = (value) => {
    setValue(value);
  };

  const handleEditorBlur = () => {
    onBlur({ target: { name } });
  };

  const onInputImageChange = async () => {
    const editor = quillRef.current.getEditor();

    const file = inputRef.current?.files[0];
    if (validateImage(file, setError)) {
      const data = await imageService.uploadImageFile(file);

      const fileUrl = data.link;

      editor.insertEmbed(editor.getSelection().index, "image", fileUrl);
    }
  };

  const imageHandler = () => {
    inputRef.current?.click();
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline"],
          ["image"],
          [{ color: [ false, "#0795A5", "#EF4F6C", "#575CA4" ] },],
          ["link"],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <div className={containerClassname}>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={onInputImageChange}
      />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        onBlur={handleEditorBlur}
        formats={formats}
        ref={quillRef}
        modules={modules}
        placeholder={placeholder}
      />
      {error && (
        <span className={cn("invalid-textarea", "invalid-input")}>{error}</span>
      )}
    </div>
  );
};

export default FormikTextEditor;
