import * as XLSX from "xlsx";

export const parseXslFile = (file, onResult) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = (e) => {
    const workbook = XLSX.read(e.target.result, { type: "array" });
    const sheet_name_list = workbook.SheetNames[0];
    const result = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list]);

    onResult(result);
  };

  reader.readAsArrayBuffer(file);
};
