import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { USERS_GROUP_LINKS, USERS_GROUP_ROUTES } from "../../../../config";
import Breadcrumb from "../../../../../../base/components/Common/Breadcrumb2";
import { userInfoTitles } from "../../../UsersList/tableStructure";
import MainUserInfo from "../../components/MainUserInfo";
import UserActions from "../../components/UserActions";
import { useService } from "../../../../../../base/hooks/useService";
import UsersService from "../../../../../../services/UsersService";
import { useUserIdFromParams } from "../../../../hooks";
import { useLoading } from "../../../../../../base/hooks/useLoading";

const Info = () => {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  const { userId } = useUserIdFromParams();
  const [user, setUser] = useState({});

  const [, { registerPromise }] = useLoading();

  useEffect(() => {
    registerPromise(usersService.getUserById(userId)).then(setUser);
  }, [registerPromise, usersService]);

  return (
    <div className="page-content users-table">
      <Breadcrumb
        backTitle="back to Users list"
        backLink={USERS_GROUP_ROUTES.BASE_USERS_LIST}
        breadcrumbItems={[
          {
            title: "Users Management",
            link: USERS_GROUP_ROUTES.BASE_USERS_LIST,
          },
          { title: "User Details" },
        ]}
      />

      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className=" h4">User details</CardTitle>
            </Col>
            {!!Object.keys(user).length && <UserActions user={user} />}
          </Row>
          {!!Object.keys(user).length && (
            <MainUserInfo user={user} userInfoTitles={userInfoTitles} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Info;
