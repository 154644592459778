import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

export default function AuthHeader({ headerText, description, icon }) {
  return (
    <div className="bg-primary bg-soft">
      <Row>
        <Col>
          <div className="pt-4 pb-5 px-4">
            <h5 className="auth-title">{headerText}</h5>
          </div>
        </Col>
        {icon && (
          <Col className="col-5 align-self-end">
            <img src={icon} alt="" className="img-fluid" />
          </Col>
        )}
      </Row>
    </div>
  );
}

AuthHeader.propTypes = {
  headerText: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
};
