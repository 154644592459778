import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Button } from "reactstrap";

import {
  PLANS,
  USER_PLAN_ANNUAL_ID,
  USER_PLAN_ANNUAL_TITLE,
  USER_PLAN_MONTHLY_ID,
  USER_PLAN_MONTHLY_TITLE,
  USER_STATUSES,
} from "../../../../../base/constants/user";
import RadioButton from "../../../../../base/components/RadioButton";
import cn from "classnames";

const USER_SUBSCRIPTION_PLANS = [
  { id: USER_PLAN_MONTHLY_ID, title: USER_PLAN_MONTHLY_TITLE },
  { id: USER_PLAN_ANNUAL_ID, title: USER_PLAN_ANNUAL_TITLE },
  ...PLANS,
];

export default function TableUserFilter({ onCreateUserClick, onApplyFilter }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState(null);
  const [subscriptionPlanFilter, setSubscriptionPlanFilter] = useState(null);

  function handleChangeStatusFilters(filter) {
    setStatusFilter((prevState) => {
      if (prevState === filter) {
        return null;
      }
      return filter;
    });
  }

  function handleChangeSubscriptionPlanFilters(filter) {
    setSubscriptionPlanFilter((prevState) => {
      if (prevState === filter) {
        return null;
      }
      return filter;
    });
  }

  const clearAllFilters = () => {
    setStatusFilter(null);
    setSubscriptionPlanFilter(null);
    onApplyFilter();
  };

  function handleSubmitFilters() {
    setIsFilterOpen((prevState) => !prevState);
    onApplyFilter(statusFilter, subscriptionPlanFilter);
  }

  return (
    <div className="d-flex flex-row justify-content-between">
      <Dropdown
        isOpen={isFilterOpen}
        toggle={() => setIsFilterOpen((prevState) => !prevState)}
      >
        <DropdownToggle
          tag="button"
          className="btn users-table--filters--dropdown__toggle"
        >
          Filter
          <i className={`mdi mdi-chevron-${isFilterOpen ? "up" : "down"}`} />
        </DropdownToggle>
        <Button
          color="link"
          className={cn("btn btn-link", {
            ["invisible"]: !statusFilter && !subscriptionPlanFilter,
          })}
          onClick={() => clearAllFilters()}
        >
          Clear all
        </Button>
        <DropdownMenu className="dropdown-menu-left ">
          <section className="users-table--filters--dropdown">
            <span className="users-table--filters--dropdown__title ">
              By subscription plan:
            </span>

            {USER_SUBSCRIPTION_PLANS.map(({ id, title }, index) => (
              <RadioButton
                key={title + index}
                text={title}
                checked={subscriptionPlanFilter === id}
                name="subscriptionPlanFilter"
                id={id}
                onClick={() => handleChangeSubscriptionPlanFilters(id)}
              />
            ))}
            <section className="users-table--filters--dropdown__next-section">
              <span className="users-table--filters--dropdown__title  mb-2">
                By status:
              </span>

              {USER_STATUSES.map(({ id, title }, index) => (
                <RadioButton
                  key={title + index}
                  text={title}
                  checked={statusFilter === id}
                  name="statusFilter"
                  id={id}
                  onClick={() => handleChangeStatusFilters(id)}
                />
              ))}
            </section>
            <Button
              outline
              color="primary"
              className="w-100 mt-2"
              onClick={handleSubmitFilters}
            >
              Apply filter
            </Button>
          </section>
        </DropdownMenu>
      </Dropdown>
      <div className="text-sm-end ms-3 ">
        <Button
          type="button"
          color="primary"
          className="btn mb-2 me-2"
          onClick={onCreateUserClick}
        >
          <i className="mdi mdi-plus me-1" />
          Create a new User
        </Button>
      </div>
    </div>
  );
}

TableUserFilter.propTypes = {};
