import React from "react";
import PropTypes from "prop-types";

const NoLessonsPlaceholder = ({ height, width, text = "No lessons created" }) => {

  return (
    <div
      className="no-lessons-placeholder "
      style={{
        ...(height && { height }),
        ...(width && { width })
      }}
    >
    <div className="text-secondary no-lessons-placeholder-text ">
      <span className="text-center">{text}</span>
    </div>
    </div>

  )
}

NoLessonsPlaceholder.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  text: PropTypes.string,
}

export default NoLessonsPlaceholder;