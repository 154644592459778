import React, {useCallback, useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import PropTypes from "prop-types";
import {DEFAULT_THEMES_FILTER_VALUE, LESSON_THEMES_FILTER_OPTIONS} from "../../../../../base/constants/lesson";
import classnames from "classnames";

export default function LessonsFilter({onSelect}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selected, setSelected] = useState(LESSON_THEMES_FILTER_OPTIONS.find(item => item.value === DEFAULT_THEMES_FILTER_VALUE))

  const handleSelect = useCallback((value) => {
    setSelected(value);
    onSelect(value);
    setIsFilterOpen(false)
  },[setSelected, onSelect])

  return (
    <div className="d-flex flex-row justify-content-between me-4">
      <Dropdown
        isOpen={isFilterOpen}
        toggle={() => setIsFilterOpen((prevState) => !prevState)}
      >
        <DropdownToggle
          tag="button"
          className="btn users-table--filters--dropdown__toggle"
        >
          <div className="filter-dropdown--label">
            {selected.label}
            <i className={` mdi mdi-chevron-${isFilterOpen ? "up" : "down"} `} />
          </div>
        </DropdownToggle>
        <DropdownMenu className="filter-dropdown--menu show__margin ">
          <section className=" filter-dropdown">
            {LESSON_THEMES_FILTER_OPTIONS.map((item, index) => {

              return(
                <div
                  key={index}
                  className={classnames(
                    "filter-dropdown--item",
                    { active: selected.value === item.value }
                  )}
                  onClick={() => handleSelect(item)}
                >{item.label}</div>
              )
            })}
          </section>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

LessonsFilter.propTypes = {
  onSelect: PropTypes.func,
};
