import React, { useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";

import FormikInput from "../../../base/components/FormikInput";
import CustomDatePicker from "../../../base/components/Datepicker";

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  birthday: yup.string().required(),
});

const initialValues = {
  firstName: "",
  lastName: "",
  birthday: "",
};

const MainInfo = ({
  step,
  steps,
  onFormChanges,
  TopNavigation,
  getInitialValues,
}) => {
  const onNextStep = useCallback((values) => {
    onFormChanges(values);
  }, []);

  const values = getInitialValues(step);

  return (
    <>
      <TopNavigation steps={steps} />
      <div className="mb-3 mt-3">
        <Formik
          initialValues={values?.formModel || initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onNextStep(values);
          }}
        >
          {(form) => {
            const { setFieldValue } = form;
            onFormChanges(form);
            return (
              <div className="w-50">
                <FormikInput
                  id="firstName"
                  label="First name"
                  name="firstName"
                  containerClassName="mb-3"
                  type="text"
                  placeholder="Enter first name"
                />
                <FormikInput
                  id="lastName"
                  label="Last name"
                  name="lastName"
                  containerClassName="mb-3"
                  type="text"
                  placeholder="Enter last name"
                />
                <label>Birthday</label>
                <CustomDatePicker
                  handleChangeDate={(date) => setFieldValue("birthday", date)}
                  initialValue={
                    `${values?.formModel?.birthday}` || initialValues.birthday
                  }
                />
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

MainInfo.propTypes = {
  step: PropTypes.number,
  steps: PropTypes.array,
  onFormChanges: PropTypes.func,
  getInitialValues: PropTypes.func,
  TopNavigation: PropTypes.func,
};

export default MainInfo;
