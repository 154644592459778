import React, {useEffect, useState} from "react";
import { Accordion as BTAccordion } from "reactstrap";
import {noop} from "../helpers/commonHelpers";

const Accordion = ({ children, setOpenIds = noop }) => {
  const [ids, setIds] = useState({ open: [] });

  const toggle = (currentId) =>
    setIds(({ open }) => ({
      open: open?.includes(currentId)
        ? open.filter((id) => id !== currentId)
        : [...open, currentId],
    }));
  
  useEffect(() => {
    setOpenIds([...ids.open]);
  }, [ids.open])

  return (
    <BTAccordion open={ids.open} toggle={toggle}>
      {children}
    </BTAccordion>
  );
};

export default Accordion;
