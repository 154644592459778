import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useField } from "formik";
import cn from "classnames";

const FormikTextarea = ({ label, containerClassName, ...props }) => {
  const [field, { error, touched }] = useField(props);

  return (
    <section className={containerClassName}>
      {label && (
        <label
          htmlFor={props.name}
          className={classNames({ "text-danger": error && touched })}
        >
          {label}
        </label>
      )}
      <textarea
        className={cn("form-control", { ["is-invalid"]: error && touched })}
        {...field}
        {...props}
      />
      {error && touched && (
        <span className={cn("invalid-textarea", "invalid-input")}>{error}</span>
      )}
    </section>
  );
};

FormikTextarea.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  label: PropTypes.string,
};

export default FormikTextarea;
