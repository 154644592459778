import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import backIcon from "../../assets/back.svg";
const Breadcrumb = (props) => {
  const { breadcrumbItems, backTitle, backLink } = props;
  const itemLength = breadcrumbItems.length;

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <Link className="h4" to={backLink}>
            <img className="me-3" src={backIcon} />
            {backTitle}
          </Link>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItems.map((item, key) => (
                <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                  <Link to={item.link || "#"}>{item.title}</Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.object,
  backTitle: PropTypes.string,
  backLink: PropTypes.string,
};

export default Breadcrumb;
