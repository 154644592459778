import React from "react";
import Icon from "../../../base/components/Icon";

export default function Logo() {
  return (
    <div className="auth-logo-light">
      <div className="avatar-md auth-icon-container mb-3">
        <span className="avatar-title rounded-circle bg-light">
          <Icon icon="logo" className="auth-icon" height="55" width="70" />
        </span>
      </div>
    </div>
  );
}
