import React from "react";
import Select from "react-select";
import cn from "classnames";

const pageSizeOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
];

const getPageNumbers = (currentPage, maxPages) => {
  const arrayOfPageNumbers = Array.from({
    length: maxPages > 5 ? 5 : maxPages,
  });

  if (arrayOfPageNumbers.length === 1) return [currentPage];

  if (currentPage === maxPages - 1) {
    arrayOfPageNumbers[arrayOfPageNumbers.length - 1] = currentPage;
  }
  if (currentPage === maxPages - 2) {
    arrayOfPageNumbers[arrayOfPageNumbers.length - 2] = currentPage;
  }
  if (currentPage === 0) {
    arrayOfPageNumbers[0] = currentPage;
  }
  if (currentPage === 1) {
    arrayOfPageNumbers[1] = currentPage;
  }
  if (
    arrayOfPageNumbers.length >= 5 &&
    !arrayOfPageNumbers.some((item) => !!item || item === 0)
  ) {
    arrayOfPageNumbers[2] = currentPage;
  }

  const insertedPageIndex = arrayOfPageNumbers.findIndex(
    (item) => !!item || item === 0
  );

  return arrayOfPageNumbers.map((pageNumber, index) => {
    if (pageNumber) return pageNumber;
    return currentPage + (index - insertedPageIndex);
  });
};

export default function Pagination({
  goToPage,
  hasPreviousPage,
  goToPreviousPage,
  goToNextPage,
  searchField,
  hasNextPage,
  pagesCount,
  currentPage,
  filters,
  pageSize,
  setPageSize,
  handleTableChange,
}) {
  return (
    <div className="pagination flex justify-content-end">
      <div className="page-item">
        <button
          onClick={() => {
            handleTableChange(currentPage, searchField, filters);
            goToPreviousPage();
          }}
          disabled={!hasPreviousPage}
          className="page-link"
        >
          {"<"}
        </button>
      </div>
      {getPageNumbers(currentPage, pagesCount).map((page, index) => {
        return (
          <div
            className={cn("page-item", { ["active"]: page === currentPage })}
            key={index}
          >
            <button
              onClick={() => {
                goToPage(page);
                handleTableChange(page + 1, searchField, filters);
              }}
              className={cn("page-link")}
            >
              {page + 1}
            </button>
          </div>
        );
      })}
      <div className="page-item">
        <button
          onClick={() => {
            handleTableChange(currentPage + 2, searchField, filters);
            goToNextPage();
          }}
          disabled={!hasNextPage}
          className="page-link"
        >
          {">"}
        </button>
      </div>

      {/*{pageSize && setPageSize && (*/}
      {/*  <Select*/}
      {/*    className="pagination-rounded__select"*/}
      {/*    options={pageSizeOptions}*/}
      {/*    defaultValue={pageSizeOptions.find(({ value }) => value === value)}*/}
      {/*    onChange={({ value }) => setPageSize(value)}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
}
