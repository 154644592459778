import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useService } from "../../../../base/hooks/useService";
import StorageService from "../../../../services/StorageService";
import qs from "query-string";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";

const noOperation = () => {};
const noChanges = (values) => values;

export const FORM_STORAGE_KEY = "FORM_STORAGE_KEY";

export const useWizardForms = ({
  onSubmit = noOperation,
  onChange = noOperation,
  steps = [],
  handleStepChange = noOperation,
  stepSubmitTransformer = noChanges,
} = {}) => {
  /**
     @type {StorageService}
     */
  const storage = useService(StorageService);
  const savedValues = storage.get(FORM_STORAGE_KEY, []);

  const forms = useRef({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState(savedValues);

  const { params } = useLocationQuery();
  const navigate = useNavigate();

  const getCurrentStepId = () => {
    return params.step || 0;
  };

  const isLast = (currentStepId) =>
    steps[steps.length - 1]?.id === currentStepId;

  const onFormChanges = (form) => {
    const id = getCurrentStepId();
    if (forms.current[id]?.values !== form.values) {
      forms.current[id] = form;
    }
    handleStepChange(id);
  };

  const getInitialValues = (id) => {
    return values[id];
  };

  const onClickPrev = () => {
    const stepId = getCurrentStepId();

    if (values[stepId]) {
      const newValues = [...values];
      delete newValues[stepId];
      setValues(newValues);
    }

    const currentIndex = steps.findIndex((step) => step.id === stepId);

    if (currentIndex > 0) {
      navigate({
        search: qs.stringify({
          ...params,
          step: stepId - 1,
        }),
      });
    }
  };

  const goToNext = () => {
    const stepId = getCurrentStepId();
    if (steps.length - 1 > steps.findIndex((step) => step.id === stepId)) {
      navigate({
        search: qs.stringify({
          ...params,
          step: stepId + 1,
        }),
      });
    }
  };

  const onClickNext = () => {
    let form;
    const stepId = getCurrentStepId();

    if (isSubmitting) return;
    setIsSubmitting(true);

    form = forms.current[stepId];

    if (!form) {
      setIsSubmitting(false);
      setValues([...values]);
      return goToNext();
    }

    form
      .submitForm()
      .then(() => {
        if (!form.dirty && (!form.dirty || form.isValid) && !values[stepId])
          return;
        setValues((values) => {
          values[stepId] = stepSubmitTransformer({
            formModel: form.values,
            isValid: true,
          });
          return [...values];
        });

        goToNext();
      })
      .finally(() => setIsSubmitting(false));
  };

  const getCurrentValues = () => values;

  const handleSubmit = useCallback(async () => {
    const id = getCurrentStepId();
    const currentForm = forms.current[id];

    if (isLast(getCurrentStepId())) {
      try {
        onSubmit(values);
        storage.remove(FORM_STORAGE_KEY);
      } finally {
        currentForm && currentForm.setSubmitting(false);
      }
    } else {
      onChange(values);
      storage.set(FORM_STORAGE_KEY, values);
    }
  }, [values]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return {
    isSubmitting,
    onFormChanges,
    onClickPrev,
    onClickNext,
    getInitialValues,
    getCurrentValues,
  };
};
