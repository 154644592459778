import React from "react";

import { UsersList } from "./pages/UsersList/";
import { Route, Routes } from "react-router-dom";
import { USERS_GROUP_ROUTES } from "./config";
import UserInfo from "./pages/UserInfo";
import PageMeta from "../../base/components/MetaTags";

const Users = () => {
  return (
    <>
      <PageMeta title="User management" />
      <Routes>
        <Route
          path={USERS_GROUP_ROUTES.BASE_USERS_LIST}
          element={<UsersList />}
        />
        <Route
          path={USERS_GROUP_ROUTES.LINK_USER_DETAILS}
          element={<UserInfo />}
        />
      </Routes>
    </>
  );
};

export default Users;
