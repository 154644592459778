import Http from "./Http";
import axios from "axios";
import { IMAGE_TYPES } from "../base/constants/image";

class S3Service extends Http {
  static $displayName = "S3Service";

  /**
   * Sending request on adding file to S3
   * fileType is a constants variable
   * */
  requestLoading(model) {
    return this.post("/files", model);
  }

  async patchFiles(model) {
    return await this.patch("/files", model);
  }

  async uploadFile(url, values) {
    return await axios.post(url, values);
  }

  async deleteFile(id) {
    return await this.delete(`/admins/lessons/files/${id}`);
  }

  /**
   * Direct S3 file loading
   * */
  uploadToS3(model) {
    const { data, file } = model;
    const formData = new FormData();

    Object.keys(data.files[0].meta.formData).forEach((key) => {
      formData.append(key, data.files[0].meta.formData[key].toString());
    });

    formData.append("file", file);

    return this.uploadFile(data.files[0].meta.url, formData).then(() =>
      this.patchFiles({ fileIds: [data.files[0].file.id] }).then(
        () => data.files[0].file
      )
    );
  }

  /**
   * Simplified image uploading process
   * */

  // TODO use one of these methods:
  //  - uploadImageFiles
  //  - uploadImageFile
  async uploadImageFiles(files) {
    const promises = files.map(async (file) => {
      const { data } = await this.requestLoading([{ contentType: file.type }]);
      await this.uploadToS3({ data: data[0], file });

      return data[0];
    });

    return Promise.all(promises);
  }

  async uploadImageFile(file) {
    const data = await this.requestLoading({
      files: [{ contentType: file.type, type: IMAGE_TYPES.LESSON }],
    });
    const result = await this.uploadToS3({ data, file });

    return result;
  }
}

export default S3Service;
