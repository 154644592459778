import * as yup from "yup";
import { CATEGORY_IDS } from "../../../../../../base/constants/lesson";

const getFieldValidation = (field) =>
  yup
    .string()
    .trim(`“Lessons ${field}” field cannot contain spaces only`)
    .required(`"Lesson ${field}" field is required`);

export const validationSchema = yup.object().shape({
  type: getFieldValidation("type"),
  category: yup.string().when("type", {
    is: CATEGORY_IDS.CUSTOM,
    then: getFieldValidation("category"),
  }),
  theme: yup.string().when("type", {
    is: CATEGORY_IDS.CUSTOM,
    then: getFieldValidation("theme"),
  }),
  title: getFieldValidation("title"),
  name: getFieldValidation("name"),
  description: yup.string().notRequired(),
  content: yup.string().notRequired(),
  extraInfo: yup.string().notRequired(),
  vocabulary: yup.array().notRequired(),
  sentences: yup.array().notRequired(),
  fileId: getFieldValidation("Image").nullable(),
});

export const initialValues = {
  type: CATEGORY_IDS.ESSENTIAL,
  category: "",
  theme: "",
  title: "",
  name: "",
  description: "",
  content: "",
  extraInfo: "",
  vocabulary: "",
  sentences: "",
  fileId: "",
};
