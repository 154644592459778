import React from "react";
import trashIcon from "../../../../../../../assets/images/bx_bxs-trash.svg";
import editIcon from "../../../../../../../assets/images/bx_bxs-pencil.svg";
import checkIcon from "../../../../../../../assets/images/bx-check.svg";

export const SENTENCES_HEADERS = {
  GERMAN: "German ",
  ENGLISH: "English",
  WRONG_WORD_1: "Wrong Example 1",
  WRONG_WORD_2: "Wrong Example 2",
  WRONG_WORD_3: "Wrong Example 3",
};

export const SENTENCES_ACCESSORS = {
  GERMAN: "body",
  ENGLISH: "translation",
  WRONG_WORD_1: "wrongWord1",
  WRONG_WORD_2: "wrongWord2",
  WRONG_WORD_3: "wrongWord3",
};

export const WORDS_HEADERS = {
  GERMAN: "German ",
  ENGLISH: "English",
};

export const WORDS_ACCESSORS = {
  GERMAN: "word",
  ENGLISH: "translation",
};

const getActions = (
  currentIndex,
  editableRowIndex,
  setEditableRowIndex,
  onRemoveRow
) => (
  <div>
    <img
      src={trashIcon}
      className="me-3"
      role="button"
      onClick={() => onRemoveRow(currentIndex)}
    />
    <img
      role="button"
      src={editableRowIndex === currentIndex ? checkIcon : editIcon}
      onClick={() => {
        if (editableRowIndex !== currentIndex) {
          setEditableRowIndex(currentIndex);
        } else {
          setEditableRowIndex(null);
        }
      }}
    />
  </div>
);

export const sentencesTableStructure = [
  {
    Header: SENTENCES_HEADERS.GERMAN,
    accessor: SENTENCES_ACCESSORS.GERMAN,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: SENTENCES_HEADERS.ENGLISH,
    accessor: SENTENCES_ACCESSORS.ENGLISH,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: SENTENCES_HEADERS.WRONG_WORD_1,
    accessor: SENTENCES_ACCESSORS.WRONG_WORD_1,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: SENTENCES_HEADERS.WRONG_WORD_2,
    accessor: SENTENCES_ACCESSORS.WRONG_WORD_2,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: SENTENCES_HEADERS.WRONG_WORD_3,
    accessor: SENTENCES_ACCESSORS.WRONG_WORD_3,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row, setEditableRowIndex, editableRowIndex, onRemoveRow }) => {
      return getActions(
        row.index,
        editableRowIndex,
        setEditableRowIndex,
        onRemoveRow
      );
    },
  },
];

export const wordsTableStructure = [
  {
    Header: WORDS_HEADERS.GERMAN,
    accessor: WORDS_ACCESSORS.GERMAN,
    maxWidth: 500,
    width: 500,
  },
  {
    Header: WORDS_HEADERS.ENGLISH,
    accessor: WORDS_ACCESSORS.ENGLISH,
    maxWidth: 500,
    width: 500,
  },
  {
    Header: "Actions",
    accessor: "actions",
    maxWidth: 100,
    width: 100,
    Cell: ({ row, setEditableRowIndex, editableRowIndex, onRemoveRow }) => {
      return getActions(
        row.index,
        editableRowIndex,
        setEditableRowIndex,
        onRemoveRow
      );
    },
  },
];
