import React, { useEffect, useCallback, useState } from "react";
import { CardBody, Card } from "reactstrap";
import { Formik } from "formik";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useToaster } from "../../../../base/hooks/useToaster";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";
import FormikInput from "../../../../base/components/FormikInput";
import ExpiredLink from "../../components/ExpiredLink";

import AuthService from "../../../../services/AuthService";

import { validationSchema, initialValues } from "./form";

import { RESET_PASSWORD_SUCCESS_MESSAGE } from "../../../../base/constants/messages";
import { AUTH_GROUP_LINKS } from "../../config";

export default function ResetPassword() {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  const navigate = useNavigate();
  const [isLoading, { registerPromise }] = useLoading();
  const [isLinkExpired, setIsLinkExpired] = useState(true);
  const { displayToaster } = useToaster();

  const {
    params: { code },
  } = useLocationQuery();

  const resetPassword = ({ password }) => {
    registerPromise(
      authService
        .resetPassword({
          password,
          token: code,
        })
        .then(() => {
          displayToaster(RESET_PASSWORD_SUCCESS_MESSAGE);
          navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
        })
    );
  };

  const checkLinkRelevance = useCallback(() => {
    registerPromise(
      authService
        .checkLinkRelevance({ token: code })
        .then(() => setIsLinkExpired(false))
    );
  }, [code, registerPromise]);

  useEffect(() => {
    checkLinkRelevance();
  }, [checkLinkRelevance]);

  return (
    <>
      {isLinkExpired && <ExpiredLink />}
      {!isLinkExpired && (
        <>
          <AuthCardContainer metaText="Login">
            <Card className="overflow-hidden">
              <AuthHeader headerText="Set new password" />

              <CardBody className="pt-0">
                <Logo />

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    resetPassword(values);
                  }}
                >
                  {({ errors, handleSubmit }) => (
                    <form
                      className="form-horizontal p-2"
                      onSubmit={handleSubmit}
                    >
                      <FormikInput
                        id="password"
                        label="New password"
                        name="password"
                        containerClassName="mb-3 input-container"
                        type="password"
                        placeholder="Enter new password"
                        as="password"
                      />

                      <FormikInput
                        id="repeatPassword"
                        label="Repeat password"
                        name="repeatPassword"
                        containerClassName="mb-3 input-container"
                        type="password"
                        placeholder="Repeat new password"
                        as="password"
                      />
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light mt-3 w-100"
                        type="submit"
                        disabled={isLoading || Object.keys(errors).length}
                      >
                        Create
                      </button>
                    </form>
                  )}
                </Formik>
              </CardBody>
            </Card>
            <Toaster />
          </AuthCardContainer>
        </>
      )}
    </>
  );
}
