import React, {useCallback, useEffect, useRef, useState} from "react";
import {AccordionBody, AccordionHeader, AccordionItem} from "reactstrap";

import { useService } from "../../../../../base/hooks/useService";
import ContentTableHeader from "./TableHeader";
import ContentService from "../../../../../services/ContentService";
import Accordion from "../../../../../base/components/Accordion";
import ContentListByTheme from "./SortableListCustom";
import {INITIAL_FILTER_OPTION} from "../../../../../base/constants/lesson";
import useOnScreen from "../hooks/useOnScreen";
import NoLessonsPlaceholder from "./noLessonsPlaceholder";

const LESSONS_LIMIT = 15;

export const ContentCustomList = () => {
  /**
   * @type {ContentService}
   */
  const contentService = useService(ContentService);

  const [openIds, setOpenIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(INITIAL_FILTER_OPTION);
  const refEndDiv = useRef();
  const isVisible = useOnScreen(refEndDiv);

  const handleSelectFilter = useCallback((value) => {
    setFilter(value)
  }, [setFilter])

  const handleTextChange = useCallback((value) => {
    setSearchText(value)
  },[setSearchText]);

  const getLessonsTopics = useCallback(async () => {
    try {
      const newQuery = {
        limit: LESSONS_LIMIT,
        offset: 0,
        categoryId: filter.value,
        search: searchText,
      };
      const { data, pagination: newPagination } = await contentService.getLessonsTopics(newQuery);
      setPagination(newPagination)
      setData(data);
    } finally {
    }
  }, [filter, searchText]);

  const loadMoreTopics = useCallback(async () => {
    try {
      setIsLoading(true);

      if (!pagination?.nextPage || (pagination.totalCount < pagination.nextOffset) ) return

      const {data, pagination: newPagination} = await contentService.getLessonsTopics({
        limit: LESSONS_LIMIT,
        offset: pagination?.nextOffset,
        categoryId: filter.value,
        search: searchText,
      })

      setData(prev => [...prev, ...data]);
      setPagination(newPagination);
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false);
    }
  }, [pagination, setPagination, setData, setIsLoading])

  useEffect(() => {
    getLessonsTopics();
  }, [getLessonsTopics]);

  useEffect(() => {
    if (isVisible && !isLoading) {
      setIsLoading(true);
      loadMoreTopics().finally(() => setIsLoading(false));
    }
  },[isVisible])

  return (
    <div>
      <ContentTableHeader
        isCustom={true}
        onSelectFilter={handleSelectFilter}
        onSearchChange={handleTextChange}
        searchPlaceholder="Search by theme..."
      />
        <Accordion className="bg-white" setOpenIds={setOpenIds}>
          {data?.length ? (
            data.map(({title, id, categoryId}, index)=>
              <div className="cursor-pointer my-1 border-1 border-info d-flex flex-row justify-content-between bg-white w-100" key={id}>
                <AccordionItem key={id} className="w-100">
                  <AccordionHeader
                    targetId={String(id)}
                    className="text-break position-relative"
                  >
                    <div>{title}</div>
                  </AccordionHeader>
                  <AccordionBody accordionId={String(id)} className="text-break text-secondary">
                    <ContentListByTheme id={id} categoryId={categoryId} sortAllowed={!searchText.length} isOpen={openIds.includes(String(id))} />
                  </AccordionBody>
                </AccordionItem>
              </div>
            )
          ) : (
            <NoLessonsPlaceholder height="605px" text={searchText && "No results found"} />
          )}
        </Accordion>
      <div ref={refEndDiv}/>
    </div>
  );
};
