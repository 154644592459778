const BASE = "/app/content-management";

export const CONTENT_MANAGEMENT_GROUP_LINKS = {
  BASE,
  BASE_ESSENTIAL_LIST: BASE.concat("/essential"),
  BASE_CUSTOM_LIST: BASE.concat("/custom"),
  CREATE_LESSON: BASE.concat("/create-lesson"),
  EDIT_LESSON: BASE.concat("/edit-lesson"),
};

export const CONTENT_MANAGEMENT_GROUP_ROUTES = {
  BASE: "/content-management/*",
  BASE_ESSENTIAL_LIST: "/*",
  BASE_CUSTOM_LIST: "/custom",
  CREATE_LESSON: "/create-lesson",
  EDIT_LESSON: "/edit-lesson/:lessonId",
};
