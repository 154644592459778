import React from "react";
import PropTypes from "prop-types";

import LessonsFilter from "./LessonsFilter";
import ContentTableFilter from "./ContentTableFilter";
import {noop} from "../../../../../base/helpers/commonHelpers";

export default function ContentTableHeader({
  isCustom = false,
  onSelectFilter = noop,
  onSearchChange = noop,
  searchPlaceholder = "Search"
}) {

  return (
    <section className="d-flex flex-row justify-content-between users-table--filters col-12">
      <section className="d-flex flex-row justify-content-end col-12 align-items-center position-relative">
        <span className="position-absolute start-0 font-size-15 font-weight-semibold">List of lessons</span>
        {isCustom &&
          <div className="d-flex align-items-center ">
            <span className="me-3 font-size-14 text-secondary ">Category:</span>
            <LessonsFilter onSelect={onSelectFilter}/>
          </div>}
        <ContentTableFilter updateSearch={onSearchChange} placeholder={searchPlaceholder}/>
      </section>
    </section>
  );
}

ContentTableHeader.propTypes = {
  isCustom: PropTypes.bool,
  onSelectFilter: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchPlaceholder: PropTypes.string,
}
