const BASE = "/app/users";

export const USERS_GROUP_LINKS = {
  BASE,
  LINK_USER_DETAILS: BASE.concat("/:id"),
};

export const USERS_GROUP_ROUTES = {
  BASE: "/users/*",
  BASE_USERS_LIST: "/",
  LINK_USER_DETAILS: "/:id/*",
};
