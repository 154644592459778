import React from "react";
import PropTypes from "prop-types";

export default function TableFilter({ onSearchChange, filters }) {
  return (
    <div className="search-box me-4 mb-2 d-inline-block">
      <div className="position-relative">
        <input
          onChange={(e) => onSearchChange(e, filters)}
          className="search-bar"
          placeholder="Search"
        />
        <i className="bx bx-search-alt search-icon" />
      </div>
    </div>
  );
}

TableFilter.propTypes = {
  onSearchChange: PropTypes.func,
};
