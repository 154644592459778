import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

import cn from "classnames";
import { STATUSES_CLASSNAMES } from "../../UsersList/tableStructure";
import {
  USER_PLANS_MAP,
  USER_PLANS_STATUSES_MAP,
  USER_STATUSES_MAP,
} from "../../../../../base/constants/user";
import { formatDate } from "../../../../../base/helpers/formatDate";

const STATUS = "status";
const DURATION_TYPE = "durationType";

const MainUserInfo = ({ user, userInfoTitles }) => {
  const {
    status,
    firstName,
    lastName,
    email,
    createdAt,
    referral,
    subscription,
  } = user;

  const getFormattedUserInfo = () => {
    return {
      status,
      firstName,
      lastName,
      email,
      createdAt: formatDate(createdAt, "MMM DD, YYYY"),
      referral: referral,
      durationType: subscription?.durationType,
    };
  };
  const getFormattedUserValue = (key, value) => {
    if (!value) {
      return "-";
    }
    if (key === STATUS) {
      return USER_STATUSES_MAP[value];
    }
    if (key === DURATION_TYPE) {
      return USER_PLANS_MAP[value];
    }
    return value;
  };
  return (
    <div className="table-responsive ">
      <Table className="table-nowrap mb-0 table-borderless w-auto">
        <tbody>
          {Object.keys(getFormattedUserInfo())?.map((key, index) => {
            return (
              <tr key={index}>
                <th scope="row" className="user-info-heading">
                  {userInfoTitles[key]}
                </th>
                <td className="user-info-text">
                  <span
                    className={cn({
                      [`${
                        STATUSES_CLASSNAMES[getFormattedUserInfo()[key]]
                      } status `]:
                        STATUSES_CLASSNAMES[getFormattedUserInfo()[key]],
                    })}
                  >
                    {getFormattedUserValue(key, getFormattedUserInfo()[key])}
                  </span>

                  {key === DURATION_TYPE && subscription?.status && (
                    <span
                      className={cn(
                        "status ms-3",
                        STATUSES_CLASSNAMES[subscription.status]
                      )}
                    >
                      {USER_PLANS_STATUSES_MAP[subscription.status]}
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

MainUserInfo.propTypes = {
  user: PropTypes.object,
  userInfoTitles: PropTypes.object,
};

export default MainUserInfo;
