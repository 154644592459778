import React, { useEffect, useCallback } from "react";
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import PropTypes from "prop-types";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { FORM_STORAGE_KEY } from "./useFormWizard";
import { useService } from "../../../../base/hooks/useService";
import StorageService from "../../../../services/StorageService";

export default function TopNavigation({ steps }) {
  /**
     @type {StorageService}
     */
  const storage = useService(StorageService);
  const savedValues = storage.get(FORM_STORAGE_KEY, []);

  const navigate = useNavigate();

  const { params } = useLocationQuery();
  const { step } = params;

  const validForms = savedValues.map((form) => !!form && form.isValid);

  const goToStep = (id) => {
    if (step !== id) {
      navigate({
        search: qs.stringify({ ...params, step: id }),
      });
    }
  };

  const onDisabled = useCallback(
    (index) => {
      const isEmpty = validForms.length === 0;
      const isValidFormIndex = validForms.length - 1 >= index;

      return isEmpty || !isValidFormIndex;
    },
    [validForms]
  );

  useEffect(() => {
    if (validForms.length === 0) {
      goToStep(Object.entries(savedValues).length + 1);
      return;
    }
    goToStep(Object.entries(savedValues).length);
  }, []);

  return (
    <div className="wizard steps">
      <ul>
        {steps.map(({ id, label }, index) => {
          return (
            <NavItem key={id} className={classnames({ current: step === id })}>
              <NavLink
                className={classnames({ current: step === id })}
                disabled={onDisabled(index)}
                onClick={() => {
                  goToStep(id);
                }}
              >
                <span className="number">{id}</span> {label}
              </NavLink>
            </NavItem>
          );
        })}
      </ul>
    </div>
  );
}

TopNavigation.propTypes = {
  steps: PropTypes.array,
  isNavDisabled: PropTypes.bool,
};
