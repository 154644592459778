import Http from "./Http";

class LessonsService extends Http {
  static $displayName = "LessonsService";

  async getXlsExample(values) {
    return await this.get("/admins/lessons/example", {
      params: values,
    });
  }

  async getThemeList(values) {
    return await this.get("/admins/lessons/topics", {
      params: values,
    });
  }

  async getLesson(id) {
    return await this.get(`/admins/lessons/${id}`);
  }

  async createLesson(values) {
    return await this.post("/admins/lessons", values);
  }

  async editLesson(id, values) {
    return await this.patch(`/admins/lessons/${id}`, values);
  }
}

export default LessonsService;
