import React from "react";
import PropTypes from "prop-types";
import joinClassNames from "../helpers/joinClassNames";
import { ReactComponent as logo } from "../assets/logo.svg";
import { ReactComponent as sortAsc } from "../assets/sort-asc.svg";
import { ReactComponent as sortDesc } from "../assets/sort-desc.svg";
import { ReactComponent as sortDefault } from "../assets/sort-default.svg";

const iconsMap = {
  logo: logo,
  sortAsc: sortAsc,
  sortDesc: sortDesc,
  sortDefault: sortDefault,
};

const Icon = ({ className, icon, ...props }) => {
  let Svg = iconsMap[icon];
  const classes = joinClassNames("icon", className);

  return <Svg className={classes} {...props} />;
};

Icon.defaultProps = {
  className: "",
  icon: logo,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default Icon;
