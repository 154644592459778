export const VERIFICATION_SUCCESS_MESSAGE =
  "Email has been successfully verified";
export const VIRIFICATION_ERROR_MESSAGE = "Email hasn't been verified";
export const VERIFICATION_TOKEN_ERROR_MESSAGE =
  "Your session to verification has expired";

export const SIGN_IN_NOT_FOUND_MESSAGE =
  "Invalid credentials. Please try again";

export const EMAIL_NOT_FOUND_MESSAGE =
  "This email is not registered. Please try again";

export const FORGOT_PASSWORD_SUCCESS_MESSAGE =
  "Link for password resetting has been sent to your email";

export const RESET_PASSWORD_SUCCESS_MESSAGE =
  "New password has been successfully created";

export const RESET_PASSWORD_RESEND_SUCCESS_MESSAGE =
  "New restoration link has been sent";

export const CREATE_LESSON_SUCCESS_MESSAGE =
  "The lesson has been successfully created";

export const EDIT_LESSON_SUCCESS_MESSAGE =
  "The lesson has been successfully updated";

export const DELETE_LESSON_SUCCESS_MESSAGE = "The lesson has been deleted";

export const DELETE_USER_SUCCESS_MESSAGE = "User has been successfully deleted";

export const VALIDATION_ERRORS = {
  password: {
    length: "Password should be from 8 to 50 symbols",
    format:
      "Password should contain at least 1 digit, 1 upper and lowercase letter",
    required: "Password is required",
    match: "Passwords don’t match",
  },
  email: {
    format: "Invalid email. Please try again",
    required: "Email is required",
  },
  name: {
    format: "Cannot contain digits or symbols",
    spaces: "Cannot contain only spaces",
  },
  firstName: {
    required: "Please enter first name.",
  },
  lastName: {
    required: "Please enter last name.",
  },
  role: {
    required: "Please select role",
  },
  plan: {
    required: "Please select subscription plan",
  },
};
