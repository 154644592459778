import React from 'react';
import PropTypes from "prop-types";
import { useLocationQuery } from '../../../../base/hooks/useQueryString';

export default function BottomNavigation({ steps, isNextDisabled, onClickNext, onClickPrev }) {
    const { params: { step } } = useLocationQuery()
    return (
        <div className="actions clearfix">
            <button
                className="btn btn-primary me-4"
                disabled={!(step - 1)}
                onClick={onClickPrev}
            >
                Previous
            </button>
            <button
                className="btn btn-primary"
                disabled={isNextDisabled}
                onClick={onClickNext}
            >
                {step < steps.length ? 'Next' : 'Finish'}
            </button>
        </div>
    );
}

BottomNavigation.propTypes = {
    steps: PropTypes.array,
    isNextDisabled: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onClickNext: PropTypes.func,
    onClickPrev: PropTypes.func,
};
