import * as yup from "yup";

import { NAME_PATTERN, PASSWORD_PATTERN } from "../base/constants/patterns";
import { MIN_PASSWORD_LENGTH } from "./lengthConstants";
import { VALIDATION_ERRORS } from "../base/constants/messages";

export const USER_EMAIL = yup.string().email(VALIDATION_ERRORS.email.format);

export const USER_PASSWORD = yup
  .string()
  .min(MIN_PASSWORD_LENGTH, VALIDATION_ERRORS.password.length)
  .matches(PASSWORD_PATTERN, VALIDATION_ERRORS.password.format)
  .trim();

export const USER_NAME = yup
  .string()
  .matches(NAME_PATTERN, VALIDATION_ERRORS.name.format)
  .trim(VALIDATION_ERRORS.name.spaces);
