import {
  ALLOWED_EXTENTIONS_FILE,
  ERROR_ALLOWED_EXTENTION_MESSAGE,
  ERROR_SIZE_MESSAGE,
  MAX_FILE_SIZE,
} from "../features/DropzoneFile/constants/dropzoneConstants";

export const validateImage = (file, setError) => {
  if (file.size > MAX_FILE_SIZE) {
    setError(ERROR_SIZE_MESSAGE);
    return false;
  }
  if (!ALLOWED_EXTENTIONS_FILE.includes(file.type)) {
    setError(ERROR_ALLOWED_EXTENTION_MESSAGE);
    return false;
  }
  return true;
};
