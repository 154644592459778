import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useToaster } from "../../../../base/hooks/useToaster";

import StorageService from "../../../../services/StorageService";
import AuthService from "../../../../services/AuthService";

import { AUTH_GROUP_LINKS } from "../../config";

import { displayErrorsFromAPI } from "../../../../base/helpers/displayErrorsFromAPI";
import SessionStorage from "../../../../services/SessionStorage";

const Logout = () => {
  const navigate = useNavigate();
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);

  const [, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();

  const logoutUser = useCallback(() => {
    const isUserAuthorized = Object.keys(storageSession.getSession()).length;
    if (!isUserAuthorized) {
      navigate(AUTH_GROUP_LINKS.LINK_LOGIN, { replace: true });
      return null;
    }
    registerPromise(authService.logout())
      .then(() => {
        storage.clear();
        navigate(AUTH_GROUP_LINKS.LINK_LOGIN, { replace: true });
      })
      .catch((error) => displayErrorsFromAPI(displayToaster, error.message));
  }, [registerPromise, authService, storage, navigate]);

  useEffect(() => {
    logoutUser();
  }, []);

  return null;
};

Logout.propTypes = {
  logoutUser: PropTypes.func,
};

export default Logout;
