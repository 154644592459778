import React from "react";
import {
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";

import { ContentEssentialList } from "./components/ContentEssentialList";
import { ContentCustomList } from "./components/ContentCustomList";
import {
  CONTENT_MANAGEMENT_GROUP_LINKS,
  CONTENT_MANAGEMENT_GROUP_ROUTES,
} from "../../config";

const FIRST_TAB = CONTENT_MANAGEMENT_GROUP_LINKS.BASE;
const SECOND_TAB = CONTENT_MANAGEMENT_GROUP_LINKS.BASE_CUSTOM_LIST;

export default function ContentTabs() {
  const location = useLocation();
  const currentLocation = location.pathname;

  const navigate = useNavigate();

  const toggleCustom = ({ path }) => {
    navigate(CONTENT_MANAGEMENT_GROUP_LINKS.BASE + path, { replace: true });
  };

  return (
    <>
      <Card className="content-details--tabs">
        <Nav tabs className="nav-tabs-custom disable-border-bottom nav-justified w-50">
          <NavItem className="content-details--tabs__tab ">
            <NavLink
              className={classnames({ active: currentLocation === FIRST_TAB }, "nav-item-border")}
              onClick={() => {
                toggleCustom({ path: "" });
              }}
            >
              <span className="d-none d-sm-block content-details--tabs__tab__span">
                Essential learning
              </span>
            </NavLink>
          </NavItem>
          <NavItem className="content-details--tabs__tab">
            <NavLink
              className={classnames( "nav-item-border", { active: currentLocation === SECOND_TAB },)}
              onClick={() => toggleCustom({ path: "/custom" })}
            >
              <span className="d-none d-sm-block content-details--tabs__tab__span">
                Custom learning
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="text-sm-end position-absolute end-0">
          <Button
            type="button"
            color="primary"
            className="btn mb-2 me-2"
            onClick={() =>
              navigate(CONTENT_MANAGEMENT_GROUP_LINKS.CREATE_LESSON)
            }
          >
            <i className="mdi mdi-plus me-1" />
            Create lesson
          </Button>
        </div>
      </Card>
      <TabContent activeTab={currentLocation}>
        <TabPane tabId={currentLocation} />
        <Routes>
          <Route path={"/"} element={<ContentEssentialList />} />
          <Route
            path={CONTENT_MANAGEMENT_GROUP_ROUTES.BASE_CUSTOM_LIST}
            element={<ContentCustomList />}
          />
        </Routes>
      </TabContent>
    </>
  );
}
