import React, { useCallback, useEffect, useState } from "react";

import { closestCenter, DndContext, useSensors } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useService } from "../../../../../base/hooks/useService";
import ContentService from "../../../../../services/ContentService";
import { ESSENTIAL_FILTER_VALUE_VALUE } from "../../../../../base/constants/lesson";
import LessonItemSortable from "./LessonItemSortable";
import {
  createHandleSortEnd,
  deleteEntity,
  getKeyboardSensor,
  getPointerSensor,
  getTouchSensor,
  preformNewOrder,
} from "../helpers/lessonHelpers";
import "react-perfect-scrollbar/dist/css/styles.css";
import LessonsListWrapper from "./LessonsListWrapper";
import { useNavigate } from "react-router-dom";
import { CONTENT_MANAGEMENT_GROUP_LINKS } from "../../../config";
import NoLessonsPlaceholder from "./noLessonsPlaceholder";
import {DELETE_LESSON_SUCCESS_MESSAGE} from "../../../../../base/constants/messages";
import {useToaster} from "../../../../../base/hooks/useToaster";

const LESSON_COUNT = 10;

const SortableListEssential = ({ searchText, sortAllowed = true }) => {
  /**
   * @type {ContentService}
   */
  const contentService = useService(ContentService);
  const navigate = useNavigate();

  const { displayToaster } = useToaster();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [updatedElementIndex, setUpdatedElementIndex] = useState(null);
  const handleSortEnd = createHandleSortEnd({
    setData,
    setUpdatedElementIndex,
  });
  const sensors = useSensors(
    getPointerSensor(),
    getKeyboardSensor(),
    getTouchSensor()
  );

  const getCustom = useCallback(async () => {
    try {
      setIsLoading(true);

      const newQuery = {
        limit: LESSON_COUNT,
        offset: 0,
        categoryId: ESSENTIAL_FILTER_VALUE_VALUE,
        search: searchText,
      };
      const { data, pagination: newPagination } =
        await contentService.getLessons(newQuery);
      setPagination(newPagination);
      setData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [searchText, setData, setIsLoading]);

  const deleteLesson = useCallback(
    async (id) => {
      try {
        const result = await contentService.deleteLessonById(id);
        setData(deleteEntity(id, data));
        displayToaster(DELETE_LESSON_SUCCESS_MESSAGE);
      } catch (e) {
        console.error(e);
      }
    },
    [data, setData]
  );

  const updateOrder = useCallback(async ({ id, body }) => {
    try {
      const result = await contentService.setLessonsOrder({ id, body });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadMoreLessons = useCallback(async () => {
    try {
      setIsLoading(true);

      if (
        !pagination?.nextPage ||
        pagination.totalCount < pagination.nextOffset
      )
        return;

      const { data, pagination: newPagination } =
        await contentService.getLessons({
          limit: LESSON_COUNT,
          offset: pagination?.nextOffset,
          categoryId: ESSENTIAL_FILTER_VALUE_VALUE,
          search: searchText,
        });

      setData((prev) => [...prev, ...data]);
      setPagination(newPagination);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [pagination, setPagination, setData, searchText, setIsLoading]);

  useEffect(() => {
    getCustom();
  }, [getCustom]);

  useEffect(() => {
    if (updatedElementIndex !== null) {
      const { id, body } = preformNewOrder(data, updatedElementIndex);
      setUpdatedElementIndex(null);
      updateOrder({ id, body });
    }
  }, [updatedElementIndex]);

  return (
    <div className="d-flex flex-column">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleSortEnd}
      >
        <SortableContext items={data} strategy={verticalListSortingStrategy}>
          {data?.length ? (
            <LessonsListWrapper onEndScroll={loadMoreLessons}>
              {data.map(
                (
                  { id, file, title, description, name, lessonNumber },
                  index
                ) => (
                  <LessonItemSortable
                    key={id}
                    id={id}
                    index={index}
                    lessonNumber={lessonNumber}
                    avatar={file?.link}
                    title={title}
                    lessonName={name}
                    description={description}
                    onDelete={(id) => deleteLesson(id)}
                    onEdit={() =>
                      navigate(
                        `${CONTENT_MANAGEMENT_GROUP_LINKS.EDIT_LESSON}/${id}`
                      )
                    }
                    allowDrag={!isLoading && sortAllowed}
                  />
                )
              )}
            </LessonsListWrapper>
          ) : (
            <NoLessonsPlaceholder height="605px" text={searchText ? "No results found" : "No lessons created"} />
          )}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default SortableListEssential;
