export const BUTTON_STYLES = {
  wavesEffect: "waves-effect",
  wavesLight: "waves-light",
  block: "btn-block w-100",
  rounded: "btn-rounded",
  outlined: "btn-outline",
};

export const BUTTON_SIZES = {
  small: "sm",
  middle: "md",
  large: "lg",
};

export const BUTTON_COLORS = {
  primary: "primary",
  secondary: "secondary",
  success: "success",
  info: "info",
  warning: "warning",
  danger: "danger",
  dark: "dark",
  link: "link",
  light: "light",
};
