import React, { useState } from "react";
import { Alert, Card, CardBody, Row, UncontrolledAlert } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import FormikInput from "../../../../base/components/FormikInput";
import { useService } from "../../../../base/hooks/useService";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import AuthService from "../../../../services/AuthService";

import { initialValues, validationSchema } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import { AUTH_GROUP_LINKS } from "../../config";
import { MAX_EMAIL_LENGTH } from "../../../../validation/lengthConstants";
import { useToaster } from "../../../../base/hooks/useToaster";
import { Toaster, toast } from "react-hot-toast";
import { FORGOT_PASSWORD_SUCCESS_MESSAGE } from "../../../../base/constants/messages";
import { FORGOT_PASSWORD_MESSAGES } from "../../../../base/constants/statusMessages";
import STATUS_CODE from "../../../../base/constants/statusCodes";
import ExpiredLink from "../../components/ExpiredLink";

const ForgetPasswordPage = () => {
  const [isLoading, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();
  const [errorStatusCode, setErrorStatusCode] = useState(null);

  const navigate = useNavigate();

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const sendEmailLink = (email, setErrors) => {
    registerPromise(authService.sendRestorePasswordEmail(email))
      .then(() => {
        displayToaster(FORGOT_PASSWORD_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        setErrors({
          email: FORGOT_PASSWORD_MESSAGES[error.statusCode],
        });
        setErrorStatusCode(error.statusCode);
      });
  };

  return (
    <>
      {errorStatusCode === STATUS_CODE.LIMIT ? (
        <ExpiredLink errorCode={errorStatusCode} />
      ) : (
        <AuthCardContainer metaText="Restore password">
          <Card className="overflow-hidden">
            <AuthHeader headerText="Reset password" />
            <CardBody className="pt-0">
              <Logo />

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setErrors }) => {
                  sendEmailLink(values, setErrors);
                }}
              >
                {({ errors, handleSubmit }) => (
                  <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                    <FormikInput
                      id="email"
                      name="email"
                      label="Email"
                      containerClassName="mb-4 input-container"
                      placeholder="Enter email"
                      type="email"
                      maxLength={MAX_EMAIL_LENGTH}
                    />
                    <button
                      className="btn btn-primary btn-block w-100 waves-effect waves-light"
                      type="submit"
                      disabled={isLoading || Object.keys(errors).length}
                    >
                      Reset password
                    </button>
                  </form>
                )}
              </Formik>
              <div className="mt-2 text-center">
                <p>
                  <Link
                    to={AUTH_GROUP_LINKS.LINK_LOGIN}
                    className="font-weight-medium text-primary"
                  >
                    Back to sign in
                  </Link>
                </p>
              </div>
            </CardBody>
          </Card>
        </AuthCardContainer>
      )}

      <Toaster />
    </>
  );
};

export default ForgetPasswordPage;
