export const TOASTER_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
};

export const TOASTER_COLORS = {
  [TOASTER_TYPES.SUCCESS]: "success",
  [TOASTER_TYPES.ERROR]: "danger",
};

export const TOASTER_ICONS_CLASSNAME = {
  [TOASTER_TYPES.SUCCESS]: "mdi mdi-check icon-check",
  [TOASTER_TYPES.ERROR]: null,
};
