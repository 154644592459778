import React, { useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import cn from "classnames";

import { useTranslate } from "../hooks/useTranslate";
import CustomInput from "./Input";

const PASSWORD_INPUT_TYPE = "password";
const TEXT_INPUT_TYPE = "text";

const FormikInput = ({
  placeholder,
  name,
  type,
  maxLength,
  readOnly,
  label,
  containerClassName,
  min,
  max,
  as,
}) => {
  const [field, meta] = useField({ name, type, placeholder });
  const [translate] = useTranslate();
  const isError = meta.error && meta.touched;

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <section className={containerClassName}>
      <label className={cn({ ["text-danger"]: isError })}>{label}</label>
      <CustomInput
        {...field}
        type={
          isPasswordShown && as === PASSWORD_INPUT_TYPE ? TEXT_INPUT_TYPE : type
        }
        className={cn({ ["is-invalid"]: isError })}
        maxLength={maxLength}
        readOnly={readOnly}
        placeholder={placeholder}
      />

      <span className={cn("invalid-feedback", "invalid-input")}>
        {translate(meta.error, { label, min, max })}
      </span>
      {as === PASSWORD_INPUT_TYPE && (
        <button
          className={cn("btn", "see-password")}
          type="button"
          id="password-addon"
          disabled={Boolean(!field.value)}
          onClick={() => {
            setIsPasswordShown((prev) => !prev);
          }}
        >
          <i
            className={cn("mdi", {
              ["mdi-eye-off-outline"]: isPasswordShown,
              ["mdi-eye-outline"]: !isPasswordShown,
            })}
          ></i>
        </button>
      )}
    </section>
  );
};

FormikInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  as: PropTypes.string,
};

export default FormikInput;
