import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import ToasterService from "../../services/ToastService";
import { useService } from "../../base/hooks/useService";

import "./index.scss";

import trashIcon from "../../assets/images/bx_bxs-trash.svg";
import editIcon from "../../assets/images/bx_bxs-pencil.svg";
import { TYPES } from "./constants/dropzoneConstants";

const DropzoneFile = ({
  onValidate,
  onReciveFile,
  onRemove,
  title,
  className,
  isDropContainer = true,
  ButtonComponent,
  acceptedFormats,
  fileUrl,
  type,
}) => {
  const toastr = useService(ToasterService);

  const handleAcceptedFile = useCallback((file) => {
    if ((type === TYPES.IMAGE && onValidate(file)) || type !== TYPES.IMAGE) {
      onReciveFile(file);
    }
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles.length) return;

      acceptedFiles.forEach((file) => handleAcceptedFile(file));
    },
    [handleAcceptedFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFormats,
  });

  return (
    <div
      className={classnames({ [className]: !!className }, "dropzone-file", {
        "drag-active": isDragActive,
        "bg-image-dropzone": fileUrl,
      })}
    >
      <div {...getRootProps()} className="w-100 h-100">
        <input {...getInputProps()} className={className} />
        {isDropContainer && !fileUrl && (
          <div className="upload-container">
            <i className="bx bxs-cloud-upload cloud"></i>
            <p className="upload-container__text">{title} </p>
          </div>
        )}
        {ButtonComponent && <ButtonComponent />}
        {fileUrl && (
          <div className="w-100 h-100 ">
            <img src={fileUrl} className="uploaded-image-dropzone" />
            <div className="image-dropzone-actions">
              <img
                src={trashIcon}
                className="me-3"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              />
              <img src={editIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DropzoneFile.propTypes = {
  onValidate: PropTypes.func,
  onReciveFile: PropTypes.func,
  className: PropTypes.string,
  isDropContainer: PropTypes.bool,
};

export default DropzoneFile;
