import CookiesPolicy from './pages/CookiesPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermPolicy from './pages/TermPolicy';
import {Route, Routes, Navigate} from "react-router-dom";
import React from "react";
import {PRIVACY_GROUP_ROUTES} from "./config";

const Privacy = () => {
    return (
        <Routes>
            <Route path={PRIVACY_GROUP_ROUTES.LINK_PRIVACY_POLICY} component={PrivacyPolicy}/>
            <Route path={PRIVACY_GROUP_ROUTES.LINK_TERMS} component={TermPolicy}/>
            <Route path={PRIVACY_GROUP_ROUTES.LINK_COOKIES} component={CookiesPolicy}/>
            <Navigate to={PRIVACY_GROUP_ROUTES.LINK_PRIVACY_POLICY} replace/>
        </Routes>
    )
};


export default Privacy