import React from "react";
import { Container } from "reactstrap";
import ContentTabs from "./tabs";

const LessonList = () => {
  return (
    <div className="page-content">
      <h3 className="lessons-page-header">Content Management</h3>
      <Container fluid>
        <ContentTabs />
      </Container>
    </div>
  );
};

export default LessonList;
