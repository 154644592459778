import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import CustomEditor from "../../../features/Editor";
import { EditorState } from "draft-js";

const AboutYourself = ({ steps, TopNavigation }) => {
  return (
    <>
      <TopNavigation steps={steps} />
      <div className="mb-3 mt-3">
        <Formik
          initialValues={{
            editorState: EditorState.createEmpty(),
          }}
          onSubmit={(values) => alert(JSON.stringify(values))}
          render={({ values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <CustomEditor
                keyState="editorState"
                editorState={values.editorState}
                onChange={setFieldValue}
              />
            </form>
          )}
        />
      </div>
    </>
  );
};

AboutYourself.propTypes = {
  steps: PropTypes.array,
  TopNavigation: PropTypes.func,
};

export default AboutYourself;
