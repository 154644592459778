import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "../../../../../../base/components/Common/Breadcrumb2";
import UserInfoForm from "../../../../components/UserInfoForm";
import { CustomModal } from "../../../../../auth/components/Modal";

import UsersService from "../../../../../../services/UsersService";

import { useService } from "../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useToaster } from "../../../../../../base/hooks/useToaster";
import { useUserIdFromParams } from "../../../../hooks";

import { TOASTER_TYPES } from "../../../../../../base/constants/toaster";
import {
  USER_PLAN_ANNUAL_ID,
  USER_PLAN_MONTHLY_ID,
  USER_PLAN_NONE_ID,
  USER_STATUS_LOGGED_IN_ID,
} from "../../../../../../base/constants/user";

import { USERS_GROUP_LINKS, USERS_GROUP_ROUTES } from "../../../../config";

import { validationSchema } from "./form";

const EditUserInfo = () => {
  /**
   * @type {UsersService}
   */
  const userService = useService(UsersService);
  const [isLoading, { registerPromise }] = useLoading();
  const [user, setUser] = useState({});

  const { userId } = useUserIdFromParams();
  const navigate = useNavigate();
  const [isExitModalShown, setIsExitModalShown] = useState(false);
  const { displayToaster } = useToaster();

  const linkToUserDetails = `${USERS_GROUP_LINKS.BASE}/${userId}`;

  const { firstName, lastName, email, subscription } = user;

  const editUser = useCallback(
    (values) => {
      const { email, firstName, lastName, durationType } = values;
      registerPromise(
        userService.updateUser(userId, {
          email,
          firstName,
          lastName,
          durationType: +durationType || undefined,
        })
      )
        .then(() => navigate(linkToUserDetails))
        .catch((error) => {
          error.message.forEach((error) =>
            displayToaster(error, TOASTER_TYPES.ERROR)
          );
        });
    },
    [registerPromise, userService]
  );

  useEffect(() => {
    registerPromise(userService.getUserById(userId)).then((user) => {
      const isEditAllowed =
        user.status !== USER_STATUS_LOGGED_IN_ID &&
        user.subscription?.durationType !== USER_PLAN_MONTHLY_ID &&
        user.subscription?.durationType !== USER_PLAN_ANNUAL_ID;
      if (isEditAllowed) {
        setUser(user);
      } else {
        navigate(linkToUserDetails);
      }
    });
  }, [registerPromise, userService]);

  return (
    <div className="page-content users-table">
      <Breadcrumb
        backTitle="back to User details"
        backLink={linkToUserDetails}
        breadcrumbItems={[
          {
            title: "Users Management",
            link: USERS_GROUP_ROUTES.BASE_USERS_LIST,
          },
          { title: "User Details", link: linkToUserDetails },
          { title: "Edit" },
        ]}
      />

      <Col lg={6}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardTitle className="h4">Edit user details</CardTitle>
              </Col>
            </Row>
            {!!Object.keys(user).length && (
              <UserInfoForm
                isEdit
                initialValues={{
                  firstName,
                  lastName,
                  email,
                  durationType: subscription?.durationType || USER_PLAN_NONE_ID,
                }}
                validationSchema={validationSchema}
                submitBtnText="Save changes"
                onSubmit={editUser}
                onCancel={() => setIsExitModalShown(true)}
              />
            )}
          </CardBody>
        </Card>
      </Col>

      <CustomModal
        isOpen={isExitModalShown}
        onClose={() => setIsExitModalShown(false)}
        onConfirm={() => {
          setIsExitModalShown(false);
          navigate(linkToUserDetails);
        }}
        confirmBtnText="Yes, leave"
        cancelBtnText="No, cancel"
        title="Cancel"
        type="question"
      >
        <p>Are you sure you want to leave without changes being saved?</p>
      </CustomModal>
    </div>
  );
};

export default EditUserInfo;
