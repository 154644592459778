import PropTypes from "prop-types";
import React from "react";

import {Route, Routes, Navigate} from "react-router-dom";
import {connect} from "react-redux";

import AuthRoutes from "./groups/auth";
import ContactRoutes from "./groups/contact";

import PrivateRoute from "./base/components/PrivateRoute";
import Page404 from "./base/pages/404";

import "./assets/scss/theme.scss";

import Privacy from "./groups/privacy";
import AppRoutes from "./groups/app";
import ErrorBoundary from "./base/components/ErrorBoundary";
import {PRIVACY_GROUP_ROUTES} from "./groups/privacy/config";
import {AUTH_GROUP_ROUTES} from "./groups/auth/config";
import {APP_GROUP_LINKS, APP_GROUP_ROUTES} from "./groups/app/config";
import CompleteProfile from "./groups/completeProfile";
import {COMPLETE_PROFILE_GROUP_ROUTES} from "./groups/completeProfile/config";
import {BASE_GROUP_LINKS} from "./base/config";
import {CONTACT_US_GROUP_ROUTES} from "./groups/contact/config";

const App = () => {
  return (
    <ErrorBoundary>
      <Routes>
        {/* Auth */}
        <Route path={AUTH_GROUP_ROUTES.BASE} element={<AuthRoutes/>}/>

        {/* Setup steps */}
        <Route path={COMPLETE_PROFILE_GROUP_ROUTES.BASE} element={<PrivateRoute component={CompleteProfile}/>}/>

        {/* App */}
        <Route path={APP_GROUP_ROUTES.BASE} element={<PrivateRoute component={AppRoutes}/>}/>

        <Route path="/" element={<Navigate to={APP_GROUP_LINKS.BASE}/>}/>

        {/* Privacy policy */}
        <Route path={PRIVACY_GROUP_ROUTES.BASE} element={<Privacy/>}/>

        {/* Contact Us */}
        <Route path={CONTACT_US_GROUP_ROUTES.BASE} element={<ContactRoutes/>}/>

        {/* Not found page */}
        <Route path={BASE_GROUP_LINKS.LINK_404} element={<Page404/>}/>

        <Route element={<Navigate to={BASE_GROUP_LINKS.LINK_404}/>}/>
      </Routes>
    </ErrorBoundary>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

AppRoutes.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps)(App);
