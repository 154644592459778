import React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { noop } from "../helpers/commonHelpers";
import PropTypes from "prop-types";

const DialogModal = ({
  isOpen,
  toggle,
  className = "",
  title = "",
  titleClassName = "",
  cancelButton = {
    className: "btn btn-outline-secondary",
    onClick: noop,
    text: "Cancel",
  },
  submitButton = {
    className: "btn btn-primary",
    onClick: noop,
    text: "Submit"
  },
  children,
}) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      container="modal"
      centered
    >
      <ModalHeader toggle={toggle} className={titleClassName}>{title}</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <button
          className={cancelButton.className}
          onClick={cancelButton.onClick}
        >
          {cancelButton.text}
        </button>{' '}
        <button
          className={submitButton.className}
          onClick={submitButton.onClick}
        >
          {submitButton.text}
        </button>
      </ModalFooter>
    </Modal>
  )
}

DialogModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  titleClassName: PropTypes.string,
  submitButton: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  cancelButton: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  })
}

export default DialogModal;