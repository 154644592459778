import { ReactComponent as TrashIcon } from "../../../../../assets/images/bx_bxs-trash.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/bx_bxs-pencil.svg";
import React, {useState} from "react";
import lessonImgPlaceholder from "../../../../../assets/images/lesson-icon-placegolder.svg";
import {noop} from "../../../../../base/helpers/commonHelpers";
import DialogModal from "../../../../../base/components/DialogModal";


const LessonItem = ({
  avatar,
  title,
  description,
  lessonName,
  id,
  sortableProps,
  onDelete = noop,
  onEdit = noop,
  allowDrag = true,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div>
      <div className="user-select-none dnd-tr-lesson mb-2 bg-white">
        {allowDrag ? (
          <div
            className="bx bx-menu cursor-pointer text-center align-self-center"
            {...sortableProps.attributes}
            {...sortableProps.listeners}
          />
        ) : (
          <div />
        )}
        <div className="">
          <img
            src={avatar}
            className="me-3 rounded-circle avatar-xs "
            alt=""
          />
        </div>
        <div className="text-truncate"> {title}</div>
        <div className="text-truncate"> {lessonName}</div>
        <div className="text-truncate me-3"> {description}</div>
        <div className="align-content-end">
          <TrashIcon
            className="me-3 cursor-pointer"
            onClick = {() => setModalIsOpen(true)}
            role="button"
          />
          <EditIcon className="cursor-pointer" onClick={() => onEdit(id)} role="button" />
        </div>
      </div>
      <DialogModal
        isOpen={modalIsOpen}
        toggle={() => setModalIsOpen(false)}
        submitButton={{
          text: "Delete",
          className: "btn btn-danger",
          onClick: () => onDelete(id),
        }}
        cancelButton={{
          text: "Cancel",
          onClick: () => setModalIsOpen(false),
          className: "btn btn-outline-secondary",
        }}
        title={"Deleted Lesson"}
      >
        All articles and tasks attached to this lesson will be deleted. Are you sure you want to delete this lesson?
      </DialogModal>
    </div>
  );
};

export default LessonItem;
