import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

import Breadcrumb from "../../../../base/components/Common/Breadcrumb2";
import Form from "./components/Form";

import { CONTENT_MANAGEMENT_GROUP_LINKS } from "../../config";
import { useParams } from "react-router-dom";

const CreateLesson = () => {
  const { lessonId } = useParams();
  const isEditPage = !!lessonId;

  return (
    <div className="page-content">
      <Breadcrumb
        backTitle={`${isEditPage ? "edit" : "create"} lesson`}
        backLink={CONTENT_MANAGEMENT_GROUP_LINKS.BASE}
        breadcrumbItems={[
          {
            title: "Content Management",
            link: CONTENT_MANAGEMENT_GROUP_LINKS.BASE,
          },
          { title: `${isEditPage ? "Edit" : "Create"} lesson` },
        ]}
      />

      <Card>
        <CardBody>
          <CardTitle className=" h4">General information</CardTitle>
          <Form />
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateLesson;
