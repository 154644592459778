import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { useLocationQuery } from "../../hooks/useQueryString";
import { exampleColumns, exampleData } from "./tableStructureEXAMPLE";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "../Pagination";
import Icon from "../Icon";
import joinClassNames from "../../helpers/joinClassNames";
import cn from "classnames";
import EditableCell from "./components/EditableCell";

const spinnerStyles = {
  overlay: (base) => ({ ...base, background: "rgba(255, 255, 255, 1)" }),
  spinner: (base) => ({
    ...base,
    width: "75px",
    "& svg circle": {
      stroke: "rgba(0, 0, 0, 1)",
    },
  }),
};

function calculateCurrentPage(offset, limit) {
  return parseInt((parseInt(offset) + parseInt(limit)) / parseInt(limit));
}

const EXAMPLEHeaderComponent = () => {
  return <section className="d-flex flex-row">Table</section>;
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ACS_SORT = "asc";
const DESC_SORT = "desc";
const DESC_SORT_ICON = "sortDesc";
const ASC_SORT_ICON = "sortAsc";
const SORT_DEFAULT_ICON = "sortDefault";

export const sortColumn = (columnId, sortProviders) => {
  if (!sortProviders[columnId]) return;
  const currentSortProvider = sortProviders[columnId];
  const currentSortValue = currentSortProvider.getValue();

  if (!currentSortValue) {
    currentSortProvider.setValue({ [columnId]: DESC_SORT });
    return;
  }
  currentSortProvider.setValue({
    [columnId]: currentSortValue[columnId] === ACS_SORT ? DESC_SORT : ACS_SORT,
  });
};

export const getSortIcon = (columnId, sortProviders) => {
  const currentSortProvider = sortProviders[columnId];
  const currentSortValue = currentSortProvider.getValue();
  if (currentSortValue && currentSortValue[columnId]) {
    return currentSortValue[columnId] === ACS_SORT
      ? ASC_SORT_ICON
      : DESC_SORT_ICON;
  }
  return SORT_DEFAULT_ICON;
};

export default function Table({
  columns = exampleColumns,
  data = exampleData,
  HeaderComponent,
  handleTableChange = () => {},
  totalCount = 0,
  limit = 15,
  offset = 15,
  isRowClick = false,
  loading = false,
  rowClickPath,
  onRowClick,
  filters,
  searchField = "",
  withLocation = true,
  sortProviders,
  emptyLabel,
  tableClassname,
  onUpdateData,
  isPagination = true,
  onRemoveRow,
  onSearchChange,
  onApplyFilter,
  ...props
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const [editableRowIndex, setEditableRowIndex] = useState(null);

  const { params: query } = useLocationQuery();

  const rowEvents = {
    onClick: (event, row) => {
      const path =
        (withLocation ? location.pathname : "") +
        (rowClickPath ? rowClickPath + `/${row.id}` : `/${row.id}`);
      navigate(path);
    },
  };

  const defaultColumn = {
    Cell: EditableCell,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      onUpdateData,
      onRemoveRow,
      editableRowIndex,
      setEditableRowIndex,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / limit),
    },
    usePagination
  );

  return (
    <Row className="m-0">
      {HeaderComponent && (
        <HeaderComponent
          onSearchChange={onSearchChange}
          onApplyFilter={onApplyFilter}
          filters={filters}
          handleTableChange={handleTableChange}
          {...props}
        />
      )}
      {(Boolean(page.length) && (
        <div className={cn("table-responsive react-table", tableClassname)}>
          <table {...getTableProps()} className="table">
            <thead className="table-light table-nowrap">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps({
                        style: {
                          maxWidth: column.maxWidth,
                          width: column.width,
                        },
                      })}
                      key={index}
                      className={joinClassNames(
                        column.getHeaderProps().className,
                        column.sort && "cursor-pointer"
                      )}
                      onClick={() => sortColumn(column.id, sortProviders)}
                    >
                      {column.render("Header")}
                      {column.sort && (
                        <Icon icon={getSortIcon(column.id, sortProviders)} />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={(event) => isRowClick && onRowClick(row)}
                    key={index}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: {
                              maxWidth: cell.column.maxWidth,
                              width: cell.column.width,
                            },
                          })}
                          key={index}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isPagination && (
            <Pagination
              handleTableChange={handleTableChange}
              goToPage={gotoPage}
              searchField={searchField}
              hasPreviousPage={canPreviousPage}
              goToPreviousPage={previousPage}
              goToNextPage={nextPage}
              hasNextPage={canNextPage}
              pagesCount={pageCount}
              filters={filters}
              currentPage={pageIndex}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          )}
        </div>
      )) || (
        <div className="d-flex align-items-center justify-content-center font-weight-bold font-size-16 data-empty">
          <span>{emptyLabel}</span>
        </div>
      )}
    </Row>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  dataStructure: PropTypes.any,
  HeaderComponent: PropTypes.any,
  handleTableChange: PropTypes.func,
  totalCount: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  isRowClick: PropTypes.bool,
  loading: PropTypes.bool,
  rowClickPath: PropTypes.string,
  searchField: PropTypes.string,
  withLocation: PropTypes.bool,
  emptyLabel: PropTypes.string,
};
