import React, {useCallback, useState} from "react";
import SortableListEssential from "./SortableListEssential";
import ContentTableHeader from "./TableHeader";

export const ContentEssentialList = () => {
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = useCallback((value) => {
    setSearchText(value)
  },[setSearchText]);

  return (
    <>
      <ContentTableHeader onSearchChange={handleSearchTextChange}/>

      <div className="dnd-header-lessons mb-2">
        <div></div>
        <div>Avatar</div>
        <div>Title</div>
        <div>Lessons name</div>
        <div>Description</div>
        <div >Actions</div>
      </div>

      <SortableListEssential searchText={searchText} sortAllowed={!searchText}/>
    </>
  );
};
