import Http from "./Http";

class ContentService extends Http {
    static $displayName = "ContentService";

    async getLessons(params) {
        return await this.get("/admins/lessons", { params });
    }

    async getLessonsTopics(params) {
        return await this.get("/admins/lessons/topics", { params });
    }

    async deleteLessonById(id) {
        return await this.delete(`/admins/lessons/${id}`);
    }

    async setLessonsOrder({id, body}) {
        return await this.patch(`/admins/lessons/order/${id}`, { ...body });
    }
}

export default ContentService;
