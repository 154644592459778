import React from "react";
import { Card } from "reactstrap";
import { Formik } from "formik";
import * as yup from "yup";
import FormikInput from "../../../../base/components/FormikInput";
import PropTypes from "prop-types";

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  birthday: yup.string(),
});

const initialValues = {
  firstName: "",
  lastName: "",
  birthday: "",
  email: "",
};

export default function BasicInfo({ step, onFormChanges, getInitialValues }) {
  const values = getInitialValues(step) || initialValues;
  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      isInitialValid={() =>
        validationSchema ? validationSchema.isValidSync(values) : true
      }
      onSubmit={() => {}}
    >
      {(form) => {
        onFormChanges(form);
        return (
          <>
            {/* <Navigation /> */}
            <Card className="w-50 p-4">
              <FormikInput
                id="firstName"
                label="First name"
                name="firstName"
                containerClassName="mb-3"
                type="text"
                placeholder="Enter first name"
              />

              <FormikInput
                id="lastName"
                label="Last name"
                name="lastName"
                containerClassName="mb-3"
                type="text"
                placeholder="Enter last name"
              />

              <FormikInput
                id="email"
                label="Email"
                name="email"
                containerClassName="mb-3"
                type="text"
              />
            </Card>
          </>
        );
      }}
    </Formik>
  );
}

BasicInfo.propTypes = {
  step: PropTypes.number,
  onFormChanges: PropTypes.func,
  getInitialValues: PropTypes.func,
  Navigation: PropTypes.func,
};
