import React, { useState } from "react";

import TableFilter from "./TableFilter";
import TableUserFilter from "./TableUserFilter";
import CreateUserModal from "../../../components/CreateUserModal";

export default function UsersTableHeader({
  onSearchChange,
  onApplyFilter,
  filters,
  handleTableChange,
}) {
  const [isCreateUserModalOpened, setIsCreateUserModalOpened] = useState(false);

  return (
    <>
      <section className="d-flex flex-row justify-content-between users-table--filters col-12">
        <section className="d-flex flex-row justify-content-between col-12">
          <TableFilter onSearchChange={onSearchChange} filters={filters} />
          <TableUserFilter
            onCreateUserClick={() => setIsCreateUserModalOpened(true)}
            onApplyFilter={onApplyFilter}
          />
        </section>
      </section>
      <CreateUserModal
        handleTableChange={handleTableChange}
        isOpen={isCreateUserModalOpened}
        onClose={() => setIsCreateUserModalOpened(false)}
      />
    </>
  );
}

UsersTableHeader.propTypes = {};
