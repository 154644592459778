import React from "react";

export const exampleColumns = [
  {
    Header: "№",
    accessor: (originalRow, rowIndex) => rowIndex + 1,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => <span className="font-weight-semibold">{value}</span>,
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "Visits",
    accessor: "visits",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Profile Progress",
    accessor: "progress",
  },
];

export const exampleData = Array.from({ length: 11 }).map(() => ({
  firstName: "reflection",
  lastName: "membership",
  age: 8,
  visits: 70,
  progress: 35,
  status: "complicated",
  subRows: undefined,
}));
