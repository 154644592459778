import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { AvatarUploader } from "../../../features/AvatarUploader";

const Avatar = ({ steps, TopNavigation }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const onChangeAvatar = useCallback(
    (file) => {
      setSelectedImage({
        ...file,
        preview: URL.createObjectURL(file),
      });
    },
    [setSelectedImage]
  );

  return (
    <>
      <TopNavigation steps={steps} />
      <div className="m-3">
        <AvatarUploader
          selectedImage={selectedImage?.preview}
          onChange={onChangeAvatar}
        />
      </div>
    </>
  );
};

Avatar.propTypes = {
  steps: PropTypes.array,
  TopNavigation: PropTypes.func,
};

export default Avatar;
