import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  CONTENT_MANAGEMENT_GROUP_LINKS,
  CONTENT_MANAGEMENT_GROUP_ROUTES,
} from "./config";
import CreateLesson from "./pages/CreateLesson";
import LessonList from "./pages/LessonList";
import PageMeta from "../../base/components/MetaTags";

const ContentManagement = () => {
  return (
    <>
      <PageMeta title="Content management" />

      <Routes>
        <Route
          path={CONTENT_MANAGEMENT_GROUP_ROUTES.BASE_ESSENTIAL_LIST}
          element={<LessonList />}
        />
        <Route
          path={CONTENT_MANAGEMENT_GROUP_ROUTES.CREATE_LESSON}
          element={<CreateLesson />}
        />
        <Route path={"/edit-lesson/:lessonId"} element={<CreateLesson />} />
      </Routes>
    </>
  );
};

export default ContentManagement;
