import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

const SEARCH_DELAY = 300;

export default function ContentTableFilter({ updateSearch, placeholder }) {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      updateSearch(event.target.value);
    }
  }

  const handleChange = (event) => {
    //if search string is empty
    if (!event.target.value) {
      updateSearch("");
    }
  }

  return (
    <div className="search-box d-inline-block align-self-center">
      <div className="position-relative">
        <Input
          className="search-bar-white"
          delay={SEARCH_DELAY}
          onKeyDown={handleKeyDown}
          placeholder={placeholder ? placeholder : "Search"}
          onChange={handleChange}
        />
        <i className="bx bx-search-alt search-icon" />
      </div>
    </div>
  );
}

ContentTableFilter.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
