import React from "react";
import App from "./App";
import Context from "./Context";
// import * as serviceWorker from "./serviceWorker"
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";

const Application = (
  <Context>
    <App />
    <Toaster />
  </Context>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(Application);

// serviceWorker.unregister()
