import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useField } from "formik";
import { FormGroup } from "reactstrap";
import cn from "classnames";

const FormikSelect = ({ label, containerClassName, ...props }) => {
  const [field, { error, touched }] = useField(props);

  return (
    <section className={containerClassName}>
      {label && (
        <label
          htmlFor={props.name}
          className={classNames({ "text-danger": error && touched })}
        >
          {label}
        </label>
      )}
      <select
        className={cn("form-control", { ["is-invalid"]: error && touched })}
        {...field}
        {...props}
      />
      {error && touched && (
        <span className={cn("invalid-textarea", "invalid-input")}>{error}</span>
      )}
    </section>
  );
};

FormikSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};

export default FormikSelect;
